import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Typography,
  StepConnector, stepConnectorClasses, 
  Input,
  IconButton
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { 
           logo, notify, msgIcon, topImage, petIcon ,  indoorIcon,  outdoorIcon,
           techIcon,  orgIcon ,  runIcon ,  visitIcon, cropImage, papers,
           paper1, paper2,  paper3, paper4, paper5, paper6, paper7, tickIcon,
           timer, mapIcon, calIcon, estimate,gradient, capIcon, teleIcon,  msg2Icon, 
           userIcon, activeCat, activeHanger, activeIndoor, activeOutdoor, activeMsg,
           activeVisit, deactiveTech,
           successIcon,
           chatIcon,
           phoneCheckInsIcon,
           inPersonHelpIcon,
           activePhoneCheckInsIcon,
           activeInPersonHelpIcon
          }
            from "./assets"
          import { styled } from "@mui/styles";
          import Stepper from "@mui/material/Stepper";
          import Step from "@mui/material/Step";
          import StepLabel from "@mui/material/StepLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { CalendarToday, ArrowDropDown } from "@mui/icons-material";
import dayjs from "dayjs";
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
    // Customizable Area Start
   HorizontalLinearAlternativeLabelStepper = () => {
    const steps = [
      "Send a Request for Help",
      "We Find a Teen Helper",
      "Get Assistance Fast",
    ];
    const activeStep = 2; // Change this value to test different active steps
  
    return (
      <Box>
      <Wrapper sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          sx={{
            '& .MuiStepIcon-root.Mui-active': {
              border: '2px solid #5E70DD',
              borderRadius: "50%",
              color: "white"
            },
          }}
          connector={<CustomStepConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Wrapper>
      </Box>

    );
  }
    // Customizable Area End
  render() {
    // Customizable Area Start
    let images = [
      {"img": phoneCheckInsIcon, text:"Phone Check-Ins", name:"Phone Check-Ins"},
      {"img":inPersonHelpIcon, text:"In-Person Help",name:"In-Person Help"},
    ]
    let imageObject:any = {
      0: activePhoneCheckInsIcon.default,
      1: activeInPersonHelpIcon.default,
    }
  
    let paperContent = [
      {"img": chatIcon, "title": "Daily Text Check-Ins", "content": "We’ll check in every day via text, and send a short report to up to eight (8) people in your trusted circle."},
      {"img": papers, "title": "Bi-Weekly Phone Buddy", "content": "Your student phone buddy will call twice a week, and we’ll send a short report to up to eight (8) people in your trusted circle."},
      {"img": paper1, "title": "Tech Help", "content": "Your student assistant can help you troubleshoot and fix minor issues with apps, logins, or wifi for your phone, laptop, or other devices."},
      {"img": paper2, "title": "Indoor Help", "content": "Your student assistant can help you change lightbulbs, carry in boxes or groceries, water and maintain houseplants, help tidy up kitchen and living spaces, and more."},
      {"img": paper3, "title": "Outdoor Help", "content": "Your student assistant can mow and edge your lawn, plant seeds or starters, spread mulch, blow or rake debris, and more."},
      {"img": paper4, "title": "Friendly Visits", "content": "Your student assistant will visit, play games or help with hobbies, and ask if you need help with anything. We’ll send a short report to up to eight (8) people in your trusted circle."},
      {"img": paper5, "title": "Organizing", "content": "Your student assistant can help you sort through, organize, box up, label, and arrange items in your cabinets, closets, garage, or shed. If you have items to sell online or donate, your student assistant can help with this too."},
      {"img": paper6, "title": "Pet Care", "content": "Your student assistant can walk your dog, change the litter box, fill pet food bins, brush or play with your furry family member, and more."},
    ]

    let cardsData = [
      {"cardName": "Text", "title1":"Daily Check-In via Text:", "title2":"$15 every four weeks", "content1":"You or your loved one will get a daily automated text asking if any help is needed. AI generates a summary and reports responses to your Trusted Circle (up to 8 members), alerting them if there’s no reply.", "content2":"Available throughout the US"},
      {"cardName": "Talk", "title1":"Everything in Text + Daily Call from a Student:", "title2":"$300 every four weeks", "content1":"You or your loved one gets a 20-minute daily call for a friendly chat and help check. AI transcribes the call and sends a summary to your Trusted Circle (up to 8 members).", "content2":"Includes Text plan at no extra cost Available throughout the US"},
      {"cardName": "Premium", "title1":"Includes everything in Text plus 5 hours of in-home help every 4 weeks.", "title2":"$125 every four weeks", "content1":"A student will visit to assist with tasks like hobbies, tech, housekeeping, yardwork, or just friendly conversation. AI transcribes visits and sends reports to your Trusted Circle (up to 8 members).", "content2":"Includes Text plan at no extra cost Available in some zip codes"},
      {"cardName": "Concierge", "title1":"Everything in Text + 15 Hours of Help:", "title2":"$375 every four weeks", "content1":"Get 15 hours of personalized in-home help every 4 weeks. A student assists with tasks, hobbies, walks, or conversation. AI summarizes visits and reports to your Trusted Circle (up to 8 members).", "content2":"Includes Text plan at no extra cost Add Talk plan for just $270/month extra Available in some zip codes"}
    ]
  
    return (

      <Box style={{ overflowX: "hidden" }}>
        <MainNav>
          <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "95%" }}>
              <img src={logo.default} style={{ width: "195px", height: "21px" }} />
              <StyledImage src={userIcon.default} />
            <NavWrap>
              <Box></Box>
              <Typography style={{
                fontFamily: "Manrope", fontSize: "16px", fontWeight: 700, lineHeight: "25.6px",
                textAlign: "center", color: "#39393F", cursor: "pointer"
              }} onClick={this.handleNavigation.bind(this,"UserProfileBasicBlock")}
              data-test-id = "UserProfileBasicBlockId">{this.state.userName?.fullName}</Typography>
              <Box style={{ display: "flex", gap: "10px" }}>
                <img src={notify.default} onClick={this.handleNavigationToMessages.bind(this, "0")} />
                <img src={msgIcon.default} onClick={this.handleNavigationToMessages.bind(this, "2")} />
              </Box>
            </NavWrap>
          </Box>
        </MainNav>
        <Box style={{ width: "100%" }}>
          <Typography style={webStyle.topText}>We're on a mission disguised as a high school job: Help others. Ensure safety. Build skills. End loneliness.</Typography>
          <Box style={{ position: "relative",width: "100%" , height:"476px", }}>
            <img src={topImage.default} style={{ width: "100%"}} />
            <Box style={{width:"100%", position: "absolute", top: "40%", display:"flex", justifyContent:"center", alignItems:"center"}}>
            <Box>
            <Box style={{ width: "100%", position:"absolute", left:"17%" }}>
              <Typography style={{...webStyle.imgText, maxWidth: "361px"}}>SENIOR YEAR IS AN ELITE SQUAD OF STUDENTS HELPING SENIORS IN YOUR LOCAL COMMUNITY</Typography>
              
              <Box >
                <Typography style={{
                fontFamily: "Caudex", fontSize: "48px", fontWeight: 700, lineHeight: "52.8px",color:"#FFFFFF"
              }}>Book trusted students to help out.</Typography>
            </Box>
                </Box>

            </Box>
              </Box>
              <StyledWrapper>
                <StyledBox>
                      <Box style={{display:"flex",marginTop:"40px", gap:"10px", flexWrap: "wrap", paddingLeft:"25px", justifyContent : "center"}}>
                      {
                       images.map((item:any, index:number)=> 
                        <Box key={index} style={{display:"flex", cursor : "pointer"}}>
                          <Box style={{display:"flex", justifyContent:"center", flexDirection:"column", alignItems: "center"}}>
                          <img src={(this.state.activeIndex === index) ? imageObject[this.state.activeIndex] : item.img.default} style={{height:"40px",width:"40px", color: `${(this.state.activeIndex === index) ? "red" : "blue" }` }} onClick={() => this.filterCategories(item.name, index)}/>
                          <Typography style={{...webStyle.iconText, color:`${(this.state.activeIndex === index) ? "#5E70DD" : "#AABED3" }`}}>{item.text}</Typography>

                          </Box>
                        </Box>
                      )
                     }
                     </Box>
                     <Box style={{ display:"flex", flexDirection:"column", marginTop:"30px", marginLeft: "20px"}}>
                    <Box style={{display:"flex", gap:"12px", marginTop:"30px", flexWrap: "wrap", justifyContent : "center"}}>
                      {
                        this.state.filteredCategory?.map((item:any)=>
                          <Button key={item.name} style={{...webStyle.btn, 
                            backgroundColor: this.state.selectedService === item.name ? "#5E70DD" : "#F1F5F9",
                            color: this.state.selectedService === item.name ? "white" : "black",
                          }}
                          data-test-id="filteredCategory"
                          onClick={() => this.handleSelect(item)}
                          >{item?.name}</Button>
                      )
                      }
                    </Box>
                    <BoxWrapper style={{display : "flex", justifyContent : "center"}}>
                      <Select
                      defaultValue={1}
                      style={webStyle.selectBox}
                       startAdornment={
                        <InputAdornment position="start">
                          <img src={mapIcon.default} style={{color:""}}/>
                        </InputAdornment>
                      }
                      >
                        <MenuItem key={1} value={1} >Service Address</MenuItem>
                      </Select>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        data-test-id="prefered_start_date"
                        open={this.state.isCalendarOpen}
                        value={this.state.selectedDate}
                        onChange={(newValue) => this.setState({ selectedDate: newValue })}
                        onClose={() => this.setState({ isCalendarOpen: false })}
                        slotProps={{
                          textField: {
                            sx:{
                              ...webStyle.selectBox,
                              borderRadius: "10px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "12px",
                                height: "49.88px",
                                width : "238px",
                                fontSize : "15px"
                              },
                              "& .MuiInputBase-input::placeholder": {
                                color: "#161C2D",
                                opacity: 1,
                                fontFamily : "Manrope"
                              },
                            },
                            placeholder: "Preferred Start Date",
                            onClick: () => this.setState({ isCalendarOpen: true }),
                            InputProps: {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <CalendarToday fontSize="small" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end" sx={{margin : 0}}>
                                  <IconButton onClick={() => this.setState({ isCalendarOpen: true })}>
                                    <ArrowDropDown />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                      
                      <StyledButton  data-test-id = "applyId12" onClick={() => {this.props.navigation.navigate("ConfirmServices")}}>Request</StyledButton>
                    </BoxWrapper>
                     </Box>

               </StyledBox>
               </StyledWrapper>
          </Box>
          <Container>
                <Typography style={webStyle.lowerText}>Do more with helpful young people.</Typography>
               <BoxContainer>
                <img src={cropImage.default} />
                <Box style={{display: "flex", maxWidth:"415.41px", width:"100%", flexDirection: "column", gap:"20px",height: "447px",  justifyContent: "center"}}>
                    <Typography style={{ fontFamily: "Manrope", color:"#0B132A", fontSize: "35px", fontWeight: 500, lineHeight: "50px", 
                    }}>Book students you can trust</Typography>
                    <Box style={{display:"flex", flexDirection:"column", gap:"15px"}}>
                    <Box style={{display:"flex",height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography style={webStyle.tickTypo}>All students pass a background check</Typography>
                </Box>
                <Box style={{display:"flex", height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography  style={webStyle.tickTypo}>All students have local teacher recommendations</Typography>
                </Box>
                <Box style={{display:"flex",height:"30px", alignItems:"center", gap:"5px"}}>
                  <img src={tickIcon.default} style={{width:"24px", height:"24px"}} />
                  <Typography  style={webStyle.tickTypo}>All students are approved by our team</Typography>
                </Box>
                    </Box>
                
                </Box>
               </BoxContainer>
               <Box style={{ display:"flex", width:"100%", justifyContent: "center"}}>
               <Box style={{ width:"100%", maxWidth: "1149px"}}>
               <PaperWrap>
                {
                  paperContent.map((item: any)=>
                    <PaperColor key={item.title}>
                   <Box style={{paddingTop:"30px", paddingLeft:"30px"}}>
                    <Box style={{display:"flex", gap:"10px"}}>
                      <img src={item.img.default} style={{ width:"30px", height:"30px" }} />
                      <Typography  style={webStyle.contentTitle} >{item.title}</Typography>
                    </Box>
                    <Typography style={{...webStyle.contentTypo, marginTop: "20px" }}>{item.content} </Typography>
                    </Box>
                </PaperColor>
                  )
                }
                </PaperWrap>

                </Box>
                </Box>
                   <Box style={{display:"flex", justifyContent: "center", marginTop:"30px", backgroundSize:"cover", width:"100%",
                        height: "110px", border: "1px",  backgroundImage: `url(${gradient.default})`}}>
                    <Typography style={{fontFamily: "Manrope", fontSize: "32px", color: "#000000", marginTop:"30px",
                     fontWeight: 700, lineHeight: "43.71px"}}>A Note on Safety</Typography>
                   </Box>
                <Box style={{ display:"flex", width:"100%", justifyContent: "center", flexDirection:"column", alignItems:"center"}}>
                  <Box style={{maxWidth: "1149px", width:"100%", borderRadius: "10px", backgroundColor: "#F1F5F9", marginTop: "20px"}}>
                   <Box style={{ display:"flex", flexDirection: "column", alignItems:"center", gap:"20px"}}>
                     <ContentWrap>
                      <Box className="contenTexttWrap">
                        <Box>
                      <Typography style={webStyle.typos}>We send an adult to conduct an onsite visit prior to sending any teen to your home or company.
                      </Typography>
                        <span style={webStyle.spanText }> This helps us help you better, and helps keep our teens safe.</span>
                        </Box>
                      <Typography style={webStyle.typos}>Our students are booked in pairs to promote safety, build skills, and help you better.</Typography>
                      <Typography style={webStyle.typos}>Our students are not qualified to assist with medical needs, reduced mobility, or transportation of others.</Typography>
                      </Box>
                      <Box><Typography style={webStyle.typos}>They do not provide help with:</Typography>
                      <ul style={{marginTop: "0px"}}>
                        <li style={webStyle.spanText}>
                        physical support for people with reduced mobility
                        </li>
                        <li style={webStyle.spanText}>
                        driving services
                        </li>
                        <li style={webStyle.spanText}>
                        activities of daily living such as bathing, toileting, getting dressed, walking, eating, or personal hygiene
                        </li>
                        <li style={webStyle.spanText}>
                        medical care or physical therapy
                        </li>
                        <li style={webStyle.spanText}>
                        cleaning or handling bodily fluids, soiled laundry or furniture, bathrooms or toilets
                        </li>
                        <li style={webStyle.spanText}>
                        personal caregiver services
                        </li>
                        <li style={webStyle.spanText}>
                        help with, oversight of, or verification of taking medications or vitamin supplements
                        </li>
                        <li style={webStyle.spanText}>
                        rides to or from errands or appointments
                        </li>
                        <li style={webStyle.spanText}>
                        pick-up services for medical prescriptions or regulated substances
                        </li>
                      </ul>
                      </Box>
                     </ContentWrap>
                   </Box>

                  </Box>

              <PlansWrapper>
                <Typography className="planText">Plans</Typography>
                <Typography className="flexibleText">We offer flexible monthly plans to cover a range of needs.</Typography>
                <Box style={{
                  width: "100%", display: "flex", gap: "10px",
                  justifyContent: "center", flexWrap: "wrap"
                }}>
                  {cardsData.map((item: any, index: number) =>
                    <Box key={index} className="cardAlignment" style={{ backgroundColor: `${((index % 2 === 1)) ? "white" : "#F3F4FD"}` }}>
                      <Box style={{ display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", paddingLeft: "16px", paddingRight: "16px", height: "100%", gap: "12px", alignItems: "center" }}>
                        <Typography className="cardNameText">{item.cardName}</Typography>
                        <Box>
                          <Typography className="cardTitle1Text">{item.title1}</Typography>
                          <Typography className="cardContent" >{item.content1}</Typography>
                        </Box>
                        <Box>
                          <Typography className="cardTitle1Text" style={{ lineHeight: "21.86px" }}>{item.title2}</Typography>
                          <Typography className="cardContent" style={{ lineHeight: "21.86px" }}>{item.content2}</Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              </PlansWrapper>
              <Box style={{textAlign:"center", paddingTop:"20px"}}>
                <Typography style={webStyle.caudText}>Students do the work. You're in charge.</Typography>  
                      <Button style={webStyle.localTeen}>Book a Local Student Assistant</Button>
                </Box>

                  <Box style={{...webStyle.gradientBox,marginTop:"100px", backgroundImage: `url(${gradient.default})`}}>
                  <Box style={{   paddingTop:"113px", paddingBottom:"114px", display: "flex",  flexDirection: "column", alignItems: "center", }}>
                  <StyledTyp>We're on a mission disguised as a high school job:</StyledTyp>
                  <Typography style={{fontFamily: "Caudex", fontSize: "32px",
                   fontWeight: 400, lineHeight: "35.2px", color:"#353535", marginTop:"20px", textAlign: "center"}}>
                    Help others. Ensure safety. Build skills. End loneliness.</Typography>
                    <GradWrap>
                      <Box style={{display:"flex", flexDirection:"column", gap:"20px", maxWidth: "462px",  width:"100%"}}>
                        <Typography>Youngh people have real financial needs: transportation, saving for college, helping with family finances</Typography>
                        <Typography>When you hire Senior Year teens,  you help high school students meet financial goals, develop a strong work ethic, and gain work experience that looks great on future college and job applications</Typography>
                      </Box>
                      <Box style={{display:"flex", flexDirection:"column", gap:"20px", maxWidth: "462px", width:"100%"}}>
                        <Typography>Senior Year students help you DO more;  you help them BE more. You help teens value positive community connections now, and wherever they're headed next</Typography>
                        <Typography>And when we say end loneliness we don't necessarily mean our students too. we mean our teens too. Intergenerational interactions benefit everyone!</Typography>
                      </Box>
                    </GradWrap>
                  </Box>
                </Box>
                </Box>
            <BottomWrapper>
              <Box style={{maxWidth: "457px", width:"100%", height: "203px", marginTop:"30px" }}>
                <img src={capIcon.default} />
                <Typography style={{
                  fontFamily: "Manrope", fontSize: "18px", fontWeight: 400, lineHeight: "25.2px",
                  color: "#FFFFFF", marginTop:"20px"
                }}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                <Typography style={{...webStyle.terms,fontSize:"14px", marginTop:"30px"}}>CompanyName @ 202X. All rights reserved</Typography>
              </Box>
                <Box>
              <Box style={{display:"flex", marginTop:"70px", gap: "20px", flexWrap: "wrap", justifyContent: "center"}}>
                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={msg2Icon.default} style={{width: "36px", height: "36px"}} />
                  <Box>
                    <Typography style={webStyle.footerTypo}>Contact us at</Typography>
                    <Typography style={webStyle.footerTitle}>senioryear@outlook.com</Typography>
                  </Box>
                </Box>

                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={teleIcon.default} style={{width: "36px", height: "36px"}} />
                  <Box>
                    <Typography style={webStyle.footerTypo}>Have a question?</Typography>
                    <Typography style={webStyle.footerTitle}>310-437-2766</Typography>
                  </Box>
                </Box>

                <Box>
                <Button style={webStyle.signupBtn}>Sign Up</Button>
                </Box>
              </Box>
              <TermsWrapper>
              <Box style={{display:"flex", gap:"20px", marginTop:"76px"}}>
                <Typography style={webStyle.terms} onClick={this.goTermsConditions}>Terms & Conditions</Typography>
              </Box>
              </TermsWrapper>
              </Box>
            </BottomWrapper>
            <BottomWrapperss>
              <Box style={{maxWidth: "457px", width:"100%", height: "90px", marginTop:"30px" }}>
                <img src={capIcon.default} style={{paddingLeft:"20px", width: "32.14px", height: "25.59px"}} />
                <Box style={{display:"flex", padding:"0px 20px", alignItems:"center"}}>
                <Typography style={{
                  fontFamily: "Manrope", fontSize: "10.71px", fontWeight: 400, lineHeight: "15px",
                  color: "#FFFFFF", marginTop:"10px"
                }}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                <Button style={{...webStyle.signupBtn, width:"160px", borderRadius:"10px"}} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")} >Sign Up</Button>
                </Box>
              </Box>
                <Box>
              <Box style={{display:"flex", paddingLeft: "20px", gap: "20px", flexWrap: "wrap"}}>
                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={msg2Icon.default} style={{width: "23.43px", height: "23.43px"}} />
                  <Box>
                    <Typography style={{fontFamily: "Manrope", fontSize: "7.81px", fontWeight: 400, lineHeight: "10.67px", color:"#ECECEC"}}>Contact us at</Typography>
                    <Typography style={{
                      fontFamily: "Manrope",
                      fontSize: "10.41px",
                      fontWeight: "400",
                      lineHeight: "14.22px",
                      color:"#FFFFFF"
                      }}>senioryear@outlook.com</Typography>
                  </Box>
                </Box>

                <Box style={{ display: "flex", gap: "10px" }}>
                  <img src={teleIcon.default} style={{width: "23.43px", height: "23.43px"}} />
                  <Box>
                    <Typography style={{fontFamily: "Manrope", fontSize: "7.81px", fontWeight: 400, lineHeight: "10.67px", color:"#ECECEC"}}>Have a question?</Typography>
                    <Typography style={{fontFamily: "Manrope",
                      fontSize: "10.41px",
                      fontWeight: "400",
                      color:"#FFFFFF",
                      lineHeight: "14.22px"}}>310-437-2766</Typography>
                  </Box>
                </Box>
              </Box>
              <Box style={{display:"flex", gap:"20px", marginTop:"26px",marginBottom:"26px", paddingLeft:"20px"}}>
                <Typography style={{fontFamily: "Roboto",
                fontSize: "9.21px",
                fontWeight: "400",
                lineHeight: "12.9px",
                color:"#FFFFFF"
                }}>CompanyName @ 202X. All rights reserved</Typography>

                <Typography style={{...webStyle.terms, fontSize:"12.1px", lineHeight:"12.1px"}} onClick={this.goTermsConditions}>Terms & Conditions</Typography>
              </Box>
              </Box>
            </BottomWrapperss>
               </Container>
                <Modal
                open={this.state.search}
                onClose={this.handleCloseModal}
                style={{
                  display:"flex", justifyContent:"center", alignItems:"center"
                }}
                >
            <StepperWrapper>
              <Box style={{ maxWidth:"441px", width:"100%", paddingTop:"117px", paddingBottom:"70px"}}>
                <Box style={{textAlign:"center"}}>
                <Typography className="thankText">Please Fill Out the Form</Typography>
                <Typography className="AssistText">To continue, kindly provide your Name, Cell Phone, and Email in the form below. This will help us connect you with the right assistance!</Typography>
                </Box>
            {this.HorizontalLinearAlternativeLabelStepper()}
            <Box style={{ maxWidth:"505px", width:"100%", paddingTop: "40px", display:"flex", flexDirection:"column", gap:"16px" }}>
              <Box>
              <Typography className="inputLabel">First Name</Typography>
              <Input type="text" disableUnderline className="formInput"
              value={this.state.firstName}
              data-test-id = "firstNameId"
              onChange={(event: any) => this.handleChange(event)}
              />
              </Box>
              <Box>
              <Typography className="inputLabel">Last Name</Typography>
              <Input type="text" disableUnderline className="formInput"/>
              </Box>
              <Box>
              <Typography className="inputLabel">Cell Number</Typography>
              <Input type="text" disableUnderline className="formInput"/>
              </Box>
              <Box>
              <Typography className="inputLabel">Email</Typography>
              <Input type="text" disableUnderline className="formInput"/>
              </Box>
              <Box>
              <Typography className="inputLabel">ZIP Code</Typography>
              <Select 
              className="formInput"
              IconComponent={KeyboardArrowDownIcon}
              value={1}>
                <MenuItem key= {1} value={1}>01234</MenuItem>
                <MenuItem key={2} value={2}>56780</MenuItem>
                 </Select>
              </Box>
              <Box style={{display:"flex", justifyContent: "flex-end", paddingTop:"32px", paddingBottom: "49px"}}>
                <Button className="requestBtn" onClick={this.handleModal}>Request a Student</Button>
              </Box>
            </Box>
              </Box>
            </StepperWrapper>

                </Modal>
                <Modal
                open={this.state.successModal}
                onClose={this.handleCloseModal}
                style={{
                  display:"flex", justifyContent:"center", alignItems:"center"
                }}
                >
                  <Box style={{  width:"100%", maxWidth: "605.38px",  height: "379px",  borderRadius: "24.79px", backgroundColor:"white",
                    display: "flex",  flexDirection: "column",  alignItems: "center",  justifyContent: "center",  gap: "20.2px"
                  }}>
                  <img src={successIcon.default} style={{width:"52.2px", height:"52.14px"}}/>
                  <Typography
                  style={{fontFamily: "Manrope",
                    fontSize: "21.63px",
                    fontWeight: 700,
                    lineHeight: "29.55px",
                    color:"#5E70DD"
                    }}>SUCCESS</Typography>
                      <Box style={{textAlign:"center"}}>
                      <Typography style={{fontFamily: "Manrope",  fontSize: "17.53px", fontWeight: 500,
                      lineHeight: "23.94px",  color: "#4A4A4A",textAlign: "center"}}
                      >Thank you for your interest in Senior Year!</Typography>
                <Typography style={{
                  fontFamily: "Manrope",
                  fontSize: "17.53px",
                  fontWeight: 300,
                  lineHeight: "29.31px",
                  textAlign: "center",
                  color: "#4A4A4A",
                  paddingBottom:"20px"
                }}>Our service team for your zip code will be in touch shortly.</Typography>
                <StyledButton style={{fontWeight: 600, width:"236.63px", height:"45.07px", padding:"0px"}}
                onClick={this.handleModal}
                >Continue</StyledButton>
                </Box>
                    </Box>
                </Modal>

        </Box>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const webStyle = {
  headerText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "25.6px",
    color:"#020202"
  },
  typos: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "21.86px",
    color: "#000000"
  },
  spanText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.86px",
    color: "#000000"
  },
  topText: {
    fontFamily: "Manrope",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "19.6px",
    color: "#1C1E1F",
    textAlign: "center" as "center",
    margin: "5px"
  },
  imgText: {
    fontFamily: "Manrope",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "25.2px",
    color: "#FFFFFF"
  },
  iconText: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px",
    color: "#AABED3"
  },
  lowerText: {
    fontFamily: "Caudex",
    fontSize: "48px",
    fontWeight: 400,
    lineHeight: "52.8px",
    color: "#444444",
    textAlign: "center" as "center"
  },
  contentTitle: {
    fontFamily: "Manrope",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32.78px",
    color: "#000000"
  },
  contentTypo: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.86px",
    width: "301px"
  },
  btn: {
    fontFamily: "Manrope",
    fontSize: "16px",
    height : "50px",
    fontWeight: 600,
    lineHeight: "25.6px",
    backgroundColor:"#F1F5F9",
    color: "#2E2E2E",
    textTransform: "capitalize" as "capitalize",
    borderRadius: "10px"

  },
  selectBox: {
    minWidth: "180px",
    height: "49.88px",
    fontFamily: "Manrope",
    color: "#161C2D",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.11px",
    borderRadius: "10px"
  },
    selectBox3: {
    width: "100%",
    height: "49.88px",
    fontFamily: "Manrope",
    color: "#161C2D",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.11px",
    borderRadius: "10px"
  },
  sliderContainer: {
    width: "1150px",
  },
  textTitle: {
    fontFamily: "Manrope",
    fontSize: "28px",
    fontWeight: 600,
    lineHeight: "36.4px",
    letterSpacing: "-0.01em",
    color: "#2D2D2D"
  },
  localTeen: {
    maxWidth: "370px",
    width: "100%",
    height: "55px",
    padding: "16px",
    borderRadius: "12px",
    border: "2px",
    backgroundColor: "#5E70DD",
    color:"#FFFFFF",
    fontFamily: "Manrope",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0.5px",
    textTransform: "capitalize" as "capitalize",
    marginTop: "30px"

  },
  gradientBox: {
    width: "100%",
    backgroundSize: "cover",
  },
  caudText: {
    fontFamily: "Caudex",
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "44px",
    color: "#353535"
  },
  footerTypo: {
    fontFamily: "Manrope",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "16.39px",
    color:"#ECECEC"
  },
  footerTitle: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.86px",
    color: "#FFFFFF"
  },
  terms: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#FFFFFF",
    cursor: "pointer"
  },
  signupBtn: {
    width: "108px",
    height: "34px",
    textTransform: "capitalize" as "capitalize",
    color:"#FFFFFF",
    backgroundColor: "#000000",
    fontFamily: "Epilogue",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "25.6px"
  },
  tickTypo: {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "30px",
    color:"#4F5665"
  }
};
const StyledBox = styled(Box)({
  background: "#FFFFFF",
  boxShadow: "7px 9px 36.1px 0px #0000000F",
  maxWidth: "1023px",
  width: "100%",
  paddingBottom: "20px",
  borderRadius: "8px",
})
const StyledWrapper = styled(Box)({
  position: "absolute",
  top: "70%", width: "100%",
  justifyContent: "center",
  display: "flex",
  "@media (max-width: 768px)": {
    top: "26%"
  },
})

const BoxWrapper = styled(Box)({
  display: "flex",
  gap: "16px",
  marginTop: "30px",
  flexWrap: "wrap",
  "@media (max-width: 768px)": {
    justifyContent: "center"
  },
})
const Container = styled(Box)({
  width: "100%",
  marginTop: "270px",
  "@media (max-width: 768px)": {
    marginTop: "430px",
    backgroundColor: "#F1F5F9"
  },
})

const BoxContainer = styled(Box)({
  width: "90%",
  display: "flex",
  paddingTop: "10px",
  justifyContent: "space-around",
  "@media (max-width: 768px)": {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "100%"
  },
})

const PaperWrap = styled(Box)({
  display: "flex",
  gap: "10px",
  flexWrap: "wrap",
  paddingTop: "100px",
  "@media (max-width: 768px)": {
    paddingTop: "0px",
    justifyContent: "center"
  },
})
const PaperColor = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "367px",
  width: "100%",
  height: "236px",
  backgroundColor: "#F1F5F9",
  borderRadius: "10px",
  "@media (max-width: 768px)": {
    backgroundColor: "#FFFFFF",
  },
})

const ContentWrap = styled(Box)({
  width: "100%",
  maxWidth: "942px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  paddingTop: "44px",
  paddingBottom: "45px",
  alignItems: "center",
  "@media (max-width: 768px)": {
    width: "90%",
  },
  "& .contenTexttWrap" : {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    paddingLeft: "6px",
    "@media (max-width: 768px)": {
      paddingLeft:"0px",
    },
  }
})

const GradWrap = styled(Box)({
  display: "flex",
  justifyContent: "space-around",
  marginTop: "50px",
  maxWidth: "1050px",
  width: "100%",
  "@media (max-width: 768px)": {
    flexDirection: "column",
    gap: "20px",
    textAlign: "center"
  },
})

const BottomWrapper = styled(Box)({
  width: "100%",
  backgroundColor: "#5E70DD",
  display: "flex",
  justifyContent: "space-around",
  // marginTop: "40px",
  "@media (max-width: 768px)": {
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    display:"none"
  },
})
const BottomWrapperss = styled(Box)({
  width: "100%",
  display:"none",
  backgroundColor: "#5E70DD",
  // marginTop: "40px",
  paddingBottom: "1px",
  paddingTop:"1px",
  "@media (max-width: 768px)": {
    display: "block"
  },
})
const TermsWrapper = styled(Box)({
  display: "flex",
  justifyContent: "flex-end",
  "@media (max-width: 768px)": {
    justifyContent: "center",
  },
})
const NavWrap = styled(Box)({
  width: "293px",
  borderLeft: "1px solid #D6DDEB",
  height: "48px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: "84px",
  "@media (max-width: 768px)": {
    display: "none"
  },
})

const PlansWrapper = styled(Box)({
  paddingTop: "90px",
  width: "100%",
  "& .planText": {
    fontFamily: "Manrope",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "43.71px",
    color: "#111111",
    textAlign: "center"
  },
  "& .flexibleText": {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "21.86px",
    textAlign: "center",
    color: "#353535",
    paddingBottom: "48.34px",
    paddingTop: "16px"
  },
  "& .cardAlignment": {
    maxWidth: "300px",
    height: "422px",
    borderRadius: " 4px",
    border: "1px solid #F1F5F9",
    boxShadow: "0px 0px 4px 0px #183B560D",
  },
  "& .cardNameText": {
    fontFamily: "Manrope",
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "33.6px",
    letterSpacing: "-0.41px",
    color: "#2E3630",
    textAlign: "center"
  },
  "& .cardTitle1Text": {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    color: "#2E3630"
  },
  "& .cardContent": {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#2E3630"
  }

})

const MainNav = styled(Box)({
  width: "100%",
  height: "74px",
  border: "1px solid #D6DDEB",
  paddingLeft: "84px",
  paddingRight: "84px",
  display: "flex",
  alignItems: "center",
  "@media (max-width: 768px)": {
    paddingLeft: "10px",
    width: "94%",
  },
})
const StyledImage = styled("img")({
  display: "none",
  "@media (max-width: 768px)": {
    display: "block",
  },
})
const StyledButton = styled("button")({
  width: "173px",
  height: "50px",
  padding: "16px",
  borderRadius: "12px",
  border: "2px",
  color: "#FFFFFF",
  backgroundColor: "#5E70DD",
  fontFamily: "Manrope",
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "20px",
  letterSpacing: "0.5px",
  textTransform: "capitalize" as "capitalize",
  cursor: "pointer",
  "@media (max-width: 768px)": {
    width: "309px",
  },
})  

const StyledTyp = styled("span")({
  fontFamily: "Caudex",
  fontSize: "40px",
  fontWeight: 400,
  lineHeight: "44px",
  color: "#353535",
  "@media (max-width: 768px)": {
   textAlign: "center"
  },
})
const StepperWrapper =styled(Box)({
  width:"100%",
  maxWidth:"737px",
  height:"937px",
  borderRadius: "12px",
  backgroundColor:"white",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  "& .maintainWrap": {
    display: "flex",
    alignItems: "center",
    paddingLeft:"27px",
    paddingTop:"30px",
    paddingBottom: "30px",
    gap: "16px",
    flexWrap: "wrap",
    maxWidth: "1272px",
    width:"100%",
    borderRadius: "13px",
    marginTop:"24px",
    backgroundColor:"#F1F5F9",
    "@media (max-width: 768px)": {
      justifyContent: "center"
     },
  },
  "& .thankText": {
    fontFamily: "Manrope",
    fontSize: "17.53px",
    fontWeight: 500,
    lineHeight: "23.94px",
    color: "#4A4A4A",
    textAlign: "center"
  },
  "& .AssistText": {
    fontFamily: "Manrope",
    fontSize: "17.53px",
    fontWeight: 300,
    lineHeight: "29.31px",
    color: "#4A4A4A",
    textAlign: "center",
    paddingBottom:"14.57px"
  },
  "& .littleText": {
    fontFamily: "Manrope",
    fontSize: "17.53px",
    fontWeight: 300,
    lineHeight: "29.31px"
  },
  "& .inputLabel": {
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px",
    color: "#515B6F"
  },
  "& .formInput": {
    maxWidth: "335px",
    width:"100%",
    height: "50px",
    paddingLeft: "12px",
    borderRadius: "8px",
    border: "1px solid #D6DDEB",
    fontFamily: "Manrope",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "25.6px",
    color: "#010101"
  },
  "& .requestBtn": {
    maxWidth: "215px",
    width: "100%",
    height: "45px",
    borderRadius: "12px",
    backgroundColor: "#5E70DD !important",
    color: "#FFFFFF",
    fontFamily: "Roboto",
    fontSize: "17.53px",
    fontWeight: 600,
    lineHeight: "20.54px",
    textTransform: "capitalize"
  }
})

const Wrapper = styled(Box)({
  "& .MuiStepIcon-root": {
    color: "#F8FAFB",
  },
  "& .MuiStepIcon-root.Mui-active": {
    color: "#5E70DD",
  },
  "& .MuiStepIcon-root.Mui-completed": {
    color: "#5E70DD",
    height: "2em !important",
    width: "2em !important",
  },
  "& .css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel": {
    zIndex: "99999",
  },
  "& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root": {
    height: "2em !important",
    width: "2em !important",
  },
  "& .css-1qv8q39-MuiStepConnector-line": {
    borderTopWidth: 12,
    borderColor: "#F8FAFB",

  },
  "& .css-z7uhs0-MuiStepConnector-line": {
    borderTopWidth: 12,
    borderColor: "#F8FAFB",
  },
  "& .css-117w1su-MuiStepIcon-text": {
    fill: "#5E70DD",
  },
});

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: "17px",
    left: "calc(-50% + 20px)",
    right: "calc(50% + 20px)", 
  },
  [`&.${stepConnectorClasses.line}`]: {
    borderTopWidth: 12, 
    borderColor: "#F8FAFB", 
  },
  [`&.${stepConnectorClasses.active}`]: {
    "& .MuiStepConnector-line": {
      borderColor: "#5E70DD",
      borderTopWidth: 12,
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    "& .MuiStepConnector-line": {
      borderColor: "#5E70DD",
      borderTopWidth: 12,
    },
  },
}));

   

// Customizable Area End
