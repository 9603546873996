import React from "react";

// Customizable Area Start
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/styles";
import { bottomImage, gradiant,gradient, image5, image6, image7, Logo, landingImg, userIcon, papers, papers1, papers2, papers3, papers4, papers5, papers7, papers6 } from "./assets"


const StyledMainDiv = styled(Box)({
    overflowX:"hidden",
    justifyContent: "space-around",
    "@media (max-width: 457px)": {
        backgroundColor: "#F1F5F9",
        justifyContent: "center",
    },
})
const StyledBox = styled(Box)({
    "@media (max-width: 678px)": {
        display:"none"
    },
})

const StyledWrapper = styled(Box)({
        width: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    "@media (max-width: 678px)": {
        width:"100%",
        height:"500px",
        justifyContent:"center"
    },
})
const StyledContent = styled(Box)({
    "@media (max-width: 678px)": {
        flexDirection: "column"
    },
})
const StyledmiddleWrap = styled(Box)({
     width: "100%",
    "@media (max-width: 678px)": {
    },
})
const StyledDiv = styled(Box)({
    "@media (max-width: 678px)": {
        justifyContent:"center",
        alignItems: "flex-start !important"
    },
})

const StyledImg = styled(Box)({
    display:"none",
    "@media (max-width: 678px)": {
        display:"block",
    },
})

const StyledFooter = styled(Box)({
        width: "93.6%",
         backgroundColor: "#5E70DD",
        height: "264px",
        display: "flex",
        alignItems: "center",
        paddingRight: "80px",
        paddingLeft: "80px",
        justifyContent: "space-between",
      "@media (max-width: 678px)": {
        width: "61.6% !important",
        height: "500px !important",
        justifyContent:"center !important",
        flexDirection:"column",
        display: "none !important"
    },
})
const ContactWrapper = styled(Box)({
  
  "@media (max-width: 678px)": {
    alignItems:"center",
    marginTop: "20px",
    flexDirection:"column"
},
})

const BoxWrapper = styled(Box)({
     width: "74%",
     height: "60%",
    "@media (max-width: 678px)": {
        width: "90%",
        height: "90%",
        display: "flex",
       alignItems:"center",
      flexDirection:"column"
  },
  "& .headingText": {
    fontFamily: "Caudex",
    fontSize: "40px",
    fontWeight: 700,
    width: "92%",
    lineHeight: "44px",
    color: "#21272A",
    "@media (max-width: 768px)": {
        display: "none",
        fontSize: "24px",
        lineHeight: "26px",
    },
  },
  "& .headingContent": {
      fontFamily: "Manrope",
      fontSize: "28px",
      fontWeight: 500,
      lineHeight: "40px",
    //   width: "240px",
      "@media (max-width: 768px)": {
          display: "none"
      },
  }
  })

  const TypoWrapper = styled("span")({
    display:"none",
   "@media (max-width: 678px)": {
    display:"block",
    fontFamily: "Caudex",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "26.4px",
    textAlign: "center"
 }
 })

 const ContentWrapper = styled("span")({
    display: "none",
   "@media (max-width: 678px)": {
    fontWeight: 500,
    display: "block",
    fontFamily:"Manrope",
    fontSize: "12px",
    lineHeight: "16.8px",
    textAlign: "center",
    width: "310px"
 }
 })

 const ImageWrapper = styled("img")({
    display:"none",
   "@media (max-width: 678px)": {
    display:"block"
 }
 })

 const Container = styled(Box)({
    display: "flex",
    height: "74px",
    alignItems: "center",
    padding: "0px 124px 0px 84px",
    justifyContent: "space-between",
   "@media (max-width: 678px)": {
    padding:"0px",
    justifyContent: "space-around",
 },
 "& .middleText" : {
    fontFamily: "Manrope",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "25.2px",
    color: "#21272A",
    "@media (max-width: 1350px)": {
     display: "none"
     
 }
}
 })

 const ContainerBox = styled(Box)({
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     maxWidth: "237px",
     width: "100%",
     height: "78px",
     gap: "10px",
   "@media (max-width: 678px)": {
    display:"none"
 }
 })

 const StyledWrap = styled(Box)({
     width: "100%",
     display: "flex",
     justifyContent: "center",
     alignItems: "center",
     padding: "52px",
     paddingBottom: "22px",
    "@media (max-width: 678px)": {
         gap: "0px",
         padding:"0px",
        justifyContent: "center",
        flexDirection: "column-reverse",
        alignItems: "center"
    },
})

const TermsWrap = styled(Box)({
    display: "flex",
    marginTop: "113px",
    gap: "10px",
    justifyContent: "flex-end",
    paddingRight: "8.2%", 
    "@media (max-width: 678px)": {
        marginTop: "20px",
        justifyContent: "center"
    }

})

const PaperWrap = styled(Box)({
    display: "flex",
    gap: "25.73px",
    flexWrap: "wrap",
    paddingTop: "32px",
    "@media (max-width: 768px)": {
      paddingTop: "0px",
      justifyContent: "center"
    },
  })
  const PaperColor = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "337.87px",
    width: "100%",
    height: "217.27px",
    backgroundColor: "#F1F5F9",
    borderRadius: "10px",
    "@media (max-width: 768px)": {
      backgroundColor: "#FFFFFF",
    },
  })

  const Wrapper = styled(Box)({
    width: "64%",
    "@media (max-width: 768px)": {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
  })

  const TypoWrap = styled("span")({
      fontFamily: "Caudex",
      fontSize: "48px",
      fontWeight: 700,
      lineHeight: "52.8px",
      color: "#21272A", 
     "@media (max-width: 768px)": {
        textAlign: "center",
        display: "flex",
        fontSize: "28px",
        lineHeight: "30px",
        padding: "24px 0px"
     },
   })

   const BottomWrapperss = styled(Box)({
    width: "100%",
    display:"none",
    backgroundColor: "#5E70DD",
    paddingBottom: "1px",
    paddingTop:"1px",
    "@media (max-width: 768px)": {
      display: "block"
    },
  })

  const StyledTyp = styled("span")({
    fontFamily: "Caudex",
    fontSize: "40px",
    fontWeight: 400,
    lineHeight: "44px",
    color: "#353535",
    "@media (max-width: 768px)": {
        textAlign: "center",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "22px",
    },

    "@media (max-width: 500px)": {
        width: "238px"
    },
  })

  const GradWrap = styled(Box)({
    display: "flex",
    justifyContent: "space-around",
    marginTop: "50px",
    maxWidth: "1050px",
    width: "100%",

    "& .justifyText": {
      textAlign: "justify",
      padding: "0 20px",
      fontWeight: 400,
      fontFamily : "Manrope",
      

      "@media (max-width: 768px)": {
        fontSize: "12px",
        lineHeight: "16px",
        },
    },

    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: "20px",
      textAlign: "center"
    },
  })
  
  const ContentWrap = styled(Box)({
    width: "100%",
    maxWidth: "942px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    paddingTop: "44px",
    paddingBottom: "45px",
    alignItems: "center",
    "@media (max-width: 768px)": {
      width: "90%",
    },
    "& .contenTexttWrap" : {
      display: "flex",
      flexDirection: "column",
      gap: "30px",
      paddingLeft: "6px",
      "@media (max-width: 768px)": {
        paddingLeft:"0px",
      },
    }
  })


// Customizable Area End

import LandingPageController, {
    Props,
} from "./LandingPageController";
export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End 
    // Customizable Area Start
    render() {
        let paperContent = [
            {"imgs": papers, "title": "Bi-Weekly Phone Buddy", "content": "Like to chat? Make friendly check-in calls twice a week to your senior clients. We use AI to transcribe your call and send a short report to your client’s trusted circle."},
            {"imgs": papers1, "title": "Tech Help", "content": "Tech-savvy? Troubleshoot and fix minor issues with your senior client’s apps, logins, or wifi on their phone, laptop, or other devices."},
            {"imgs": papers2, "title": "Indoor Help", "content": "Like helping around the house? Put out indoor decorations for your senior client, change lightbulbs, help tidy up kitchen and living spaces, carry in boxes or groceries, water houseplants, and more."},
            {"imgs": papers3, "title": "Outdoor Help", "content": "Like working outside? Mow and edge lawns for your senior client, rake leaves, plant garden seeds or starters, spread mulch or wood chips, blow or rake leaves, remove small debris, and more."},
            {"imgs": papers4, "title": "Friendly Visits", "content": "Like listening and shared activities? Play games with your senior client, help with hobbies, talk about current events, practice a shared language, and more."},
            {"imgs": papers5, "title": "Organizing", "content": "Like to organize stuff? Sort through, organize, box up, label, and arrange items in your senior client’s cabinets, closets, garage, or shed."},
            {"imgs": papers6, "title": "Pet Care", "content": "Good with furry friends? Walk your senior client’s dog, change the litter box, fill pet food bins, brush or play with their furry family member, and more."},
          ]
        return (
            <StyledMainDiv>
                <Container>
                    <Box style={{display:"flex", gap:"80px"}}>
                    <img src={Logo.default} style={{ width: "195px", height: "21px" }} />
                    <ImageWrapper src={userIcon.default} />
                    </Box>
                    <ContainerBox>
                        <Typography style={{...webStyles.typo, paddingRight:"40px"}} onClick={this.handleNavigation.bind(this,"LoginPage")} >Login</Typography>
                        <Box style={{ color: "#D6DDEB", height: "48px", border: "1px solid #D6DDEB" }}></Box>
                        <Button style={webStyles.btn}  onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign Up</Button>
                    </ContainerBox>
                </Container>
                <Box style={{ display: "flex", justifyContent: "center", padding: "8px 24px", background: "#F1F5F9"}}>
                    <Typography className="middleText" sx={{
                        "@media (max-width: 500px)": {
                            fontSize: "12px",
                            lineHeight: "16px",
                            textAlign: "center"
                        }
                    }}>We’re on a mission disguised as a student job: Help others. Ensure safety. Build skills. End loneliness.</Typography>
                </Box>
                <StyledDiv style={{ height: "676px", width: "100%", gap: "40px", display: "flex",backgroundImage: `url(${gradiant.default})`, backgroundSize:"cover", alignItems: "center" }}>
                    <StyledWrapper >
                        <BoxWrapper>
                            <TypoWrapper>Make your move to make a difference.</TypoWrapper>
                            <Typography className="headingText">Make your move to make a difference.</Typography>
                            <StyledImg>
                            <img src={landingImg.default}  style={{ width: "336.87px",height: "348.63px" }}/>
                            </StyledImg>
                            <Box style={{marginTop:"42px", marginBottom:"30px"}}>
                            <Typography className="headingContent">SENIOR YEAR IS AN ELITE SQUAD OF STUDENTS HELPING SENIORS IN THEIR COMMUNITIES <br />
                                Join the best place to find flexible student jobs while making an impact.
                            </Typography>
                            <ContentWrapper>
                                SENIOR YEAR IS AN ELITE SQUAD OF STUDENTS HELPING SENIORS IN THEIR COMMUNITIES <br />
                                Join the best place to find flexible student jobs while making an impact.
                            </ContentWrapper>
                            </Box>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <Button style={webStyles.boxBtn} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Apply Today</Button>
                                
                            </Box>
                        </BoxWrapper>
                    </StyledWrapper>
                    <StyledBox style={{ width: "45%", height: "473px" }}>
                        <img src={landingImg.default} style={{width:"100%", paddingTop:"20px", height: "100%"}}/>
                    </StyledBox>
                </StyledDiv>
                <StyledmiddleWrap >
                    <StyledWrap>
                        <Box sx={{
                            maxWidth: "436.91px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "46px",
                            "@media (max-width: 768px)": {
                                flexDirection: "column-reverse",
                                gap: "34px",
                                margin: "24px 0px"
                            }
                        }} >
                            <img src={bottomImage.default} style={{ maxWidth: "436.91px", width:"100%", height: "461.06px" }} />
                            <Typography sx={{
                                width: "348px",
                                fontSize: "28px",
                                lineHeight: "30px",
                                fontWeight: 400,
                                color: "#353535",
                                textAlign: "center",
                                fontFamily: "Caudex",
                                "@media (max-width: 768px)": {
                                    width: "272px",                              
                                }
                            }}>If you can do any of these, apply today.</Typography>
                        </Box>
                        <Wrapper>
                            <TypoWrap>What our students do:</TypoWrap>
                            <PaperWrap>
                                {
                                paperContent.map( (item: any) =>
                                    <PaperColor>
                                <Box style={{paddingTop:"30px", paddingLeft:"30px",}}>
                                    <Box style={{display:"flex", gap:"10px",}}>
                                    <img src={item.imgs.default} style={{ width:"27.62px", height:"27.62px", }} />
                                    <Typography style={webStyles.contentTitle} >{item.title}</Typography>
                                    </Box>
                                    <Typography style={{...webStyles.contentTypo, marginTop: "20px", }}>{item.content} </Typography>
                                    </Box>
                                </PaperColor>
                                )
                                }
                            </PaperWrap>
                        </Wrapper>
                    </StyledWrap>
                    

                    <Box style={{display:"flex", justifyContent: "center", marginTop:"30px", backgroundSize:"cover", width:"100%",
                        height: "110px", border: "1px",  backgroundImage: `url(${gradient.default})`}}>
                    <Typography style={{fontFamily: "Manrope", fontSize: "32px", color: "#000000", marginTop:"30px",
                     fontWeight: 700, lineHeight: "43.71px"}}>A Note on Safety</Typography>
                   </Box>
                <Box style={{ display:"flex", width:"100%", justifyContent: "center", flexDirection:"column", alignItems:"center"}}>
                  <Box style={{maxWidth: "1149px", width:"100%", borderRadius: "10px", backgroundColor: "#F1F5F9", marginTop: "20px"}}>
                   <Box style={{ display:"flex", flexDirection: "column", alignItems:"center", gap:"20px"}}>
                     <ContentWrap>
                      <Box className="contenTexttWrap">
                        <Box>
                            <Typography style={webStyles.typos}>We use AI to make a digital record of all student and senior interactions.</Typography>
                            <span style={webStyles.spanText }>This helps us create reports for your senior client’s Trusted Circle and helps keep everyone safe.</span>
                        </Box>

                        <Box>
                            <Typography style={webStyles.typos}>Our student assistants do not help with basic or instrumental activities of daily living (ADLs).</Typography>
                            <span style={webStyles.spanText }>ADLs include walking, eating, grooming, toileting, bathing, dressing, moving people or furniture, cooking, laundering clothing or bedding, cleaning bathrooms, making shopping decisions, handling financial transactions for clients, overseeing other home service providers, or driving for appointments or errands.</span>
                        </Box>

                        <Box>
                            <Typography style={webStyles.typos}>Our booking team works with senior clients to understand their needs before any student services are scheduled.</Typography>
                            <span style={webStyles.spanText }>We know students are capable of handling many tasks and responsibilities. We think of our student assistants as that extra set of part-time hands, eyes, and ears that help seniors continue to live and thrive independently. We let senior clients know if their needs would be better met with a professional housekeeper, driver, caregiver, or home maintenance professional.</span>
                        </Box>

                        <Box>
                            <Typography style={webStyles.typos}>All online payments are handled through an encrypted payment system, and your senior clients can tip you for excellent service. </Typography>
                            <span style={webStyles.spanText }>Our pay rate starts at minimum wage in your area, and yes, you can earn tips! After you provide a service, your senior client can choose to tip you for excellent work with cash or through our website. They also are asked to rate your service. You can earn a wage increase for every 100 hours of work with an excellent overall rating. Please note our student assistants are not allowed to accept hospitality, entertainment, or gifts, including unwanted household items, tools, or clothing. </span>
                        </Box>

                      </Box>
                     </ContentWrap>
                   </Box>

                  </Box>
                </Box>
                
              <Box style={{textAlign:"center", paddingTop:"20px"}}>

                  <Box style={{...webStyles.gradientBox, backgroundImage: `url(${gradient.default})`}}>
                  <Box style={{   paddingTop: "99px", paddingBottom:"68px", display: "flex",  flexDirection: "column", alignItems: "center" }}
                    sx={{
                        "@media (max-width: 768px)": {
                            paddingTop: "23px !important", 
                            paddingBottom: "23px !important"                            
                        }
                    }}
                  >
                  <StyledTyp>We’re on a mission disguised as a student job:</StyledTyp>
                  <Typography style={{fontFamily: "Caudex", fontSize: "32px",
                   fontWeight: 400, lineHeight: "35.2px", color:"#353535", marginTop:"20px", textAlign: "center"}} sx={{
                        "@media (max-width: 768px)": {
                            fontSize: "14px !important", 
                            lineHeight: "16px !important"                            
                        },
                        "@media (max-width: 500px)": {
                            width: "206px"                           
                        }
                   }}>
                    Help others. Ensure safety. Build skills. End loneliness.</Typography>
                    <GradWrap>
                      <Box style={{display:"flex", flexDirection:"column", gap:"20px", maxWidth: "462px",  width:"100%"}}>
                        <Typography className="justifyText">Young people have real financial needs: transportation, saving for college, helping with family finances.</Typography>
                        <Typography className="justifyText">When our senior clients hire Senior Year student assistants, they know they're helping you meet financial goals, develop a strong work ethic, and gain work experience that looks great on future college and work applications.</Typography>
                      </Box>
                      <Box style={{display:"flex", flexDirection:"column", gap:"20px", maxWidth: "462px", width:"100%"}}>
                        <Typography className="justifyText">As a Senior Year student assistant, you help your senior clients DO more; they help you BE more. Our senior clients help their student assistants value positive community connections now, and wherever you’re headed next.</Typography>
                        <Typography className="justifyText">And when we say “end loneliness” we don’t necessarily mean our senior clients; we mean our student assistants too. Intergenerational interactions benefit everyone!</Typography>
                      </Box>
                    </GradWrap>
                  </Box>
                </Box>
                </Box>

                <StyledFooter 
                    style={{
                        width: "93.6%", backgroundColor: "#5E70DD", height: "264px", display: "flex", alignItems: "center",
                        paddingRight: "80px", paddingLeft: "80px", justifyContent: "space-between"
                    }}
                >
                    <Box style={{ width:"100%", maxWidth: "457px", height: "203px" }}>
                        <img src={image5.default} style={{ width: "54px", height: "43px", marginBottom: "10px" }} />
                        <Typography style={webStyles.footerText}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                        <Typography style={webStyles.emailText}>CompanyName @ 202X. All rights reserved.</Typography>
                    </Box>

                    <Box style={{height:"203px",width:"600px", marginTop:"60px" }}>
                        <ContactWrapper style={{ display: "flex", gap: "10px" }}>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <img src={image6.default} style={{ width: "36px", height: "36px" }} />
                                <Box>
                                    <Typography style={webStyles.smallTypo}>Contact us at</Typography>
                                    <Typography style={webStyles.emailText}>senioryear@outlook.com</Typography>
                                </Box>
                            </Box>
                            <Box style={{ display: "flex", gap: "10px" }}>
                                <img src={image7.default} style={{ width: "36px", height: "36px" }} />
                                <Box>
                                    <Typography style={webStyles.smallTypo}>Have a question?</Typography>
                                    <Typography style={webStyles.emailText}>310-437-2766</Typography>
                                </Box>
                            </Box>
                            <Button style={webStyles.signBtn} onClick={this.handleNavigation.bind(this,"EmailAccountRegistration")}>Sign Up</Button>
                        </ContactWrapper>
                        <Box>
                            <TermsWrap>
                                <Typography style={webStyles.emailText} onClick={this.goTermsConditions} >Terms & Conditions</Typography>
                            </TermsWrap>
                        </Box>

                    </Box>



                </StyledFooter>
                <BottomWrapperss>
                <Box style={{maxWidth: "457px", width:"100%", height: "90px", marginTop:"30px" }}>
                    <img src={image5.default} style={{paddingLeft:"20px", width: "32.14px", height: "25.59px"}} />
                    <Box style={{display:"flex", padding:"0px 20px", alignItems:"center"}}>
                    <Typography style={{
                    fontFamily: "Manrope", fontSize: "10.71px", fontWeight: 400, lineHeight: "15px",
                    color: "#FFFFFF", marginTop:"10px"
                    }}>Tristique elementum, ac maecenas enim fringilla placerat scelerisque semper.</Typography>
                    <Button style={{...webStyles.signupBtn, width:"160px", borderRadius:"10px"}}
                    onClick={() => this.handleNavigation("EmailAccountRegistration")}
                    data-test-id = "signupId">Sign Up</Button>
                    </Box>
                </Box>
                    <Box>
                <Box style={{display:"flex", paddingLeft: "20px", gap: "20px", flexWrap: "wrap"}}>
                    <Box style={{ display: "flex", gap: "10px" }}>
                    <img src={image7.default} style={{width: "23.43px", height: "23.43px"}} />
                    <Box>
                        <Typography style={{fontFamily: "Manrope", fontSize: "7.81px", fontWeight: 400, lineHeight: "10.67px", color:"#ECECEC"}}>Contact us at</Typography>
                        <Typography style={{
                        fontFamily: "Manrope",
                        fontSize: "10.41px",
                        fontWeight: "400",
                        lineHeight: "14.22px",
                        color:"#FFFFFF"
                        }}>senioryear@outlook.com</Typography>
                    </Box>
                    </Box>

                    <Box style={{ display: "flex", gap: "10px" }}>
                    <img src={image6.default} style={{width: "23.43px", height: "23.43px"}} />
                    <Box>
                        <Typography style={{fontFamily: "Manrope", fontSize: "7.81px", fontWeight: 400, lineHeight: "10.67px", color:"#ECECEC"}}>Have a question?</Typography>
                        <Typography style={{fontFamily: "Manrope",
                        fontSize: "10.41px",
                        fontWeight: "400",
                        color:"#FFFFFF",
                        lineHeight: "14.22px"}}>310-437-2766</Typography>
                    </Box>
                    </Box>
                </Box>
                <Box style={{display:"flex", gap:"20px", marginTop:"26px",marginBottom:"26px", paddingLeft:"20px"}}>
                    <Typography style={{fontFamily: "Roboto",
                    fontSize: "9.21px",
                    fontWeight: "400",
                    lineHeight: "12.9px",
                    color:"#FFFFFF"
                    }}>CompanyName @ 202X. All rights reserved</Typography>

                    <Typography style={{...webStyles.terms, fontSize:"12.1px", lineHeight:"12.1px"}} onClick={this.goTermsConditions}>Terms & Conditions</Typography>
                </Box>
                </Box>
                </BottomWrapperss>
                </StyledmiddleWrap>
            </StyledMainDiv>
        )
    }
}

export const webStyles = {
    typo: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#5E70DD",
        cursor: "pointer",
    },
    iconWidth: {
        width: "40px",
        height: "36px",
        marginBottom: "16px"
    },
    imgText: {
        fontFamily: "Roboto",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",

    },
    boxAlign: {
        maxWidth: "380px",
        width: "100%",
        height: "114px",
    },
    btn: {
        textTransform: "initial" as "initial",
        height: "50px",
        padding: "12px 24px 12px 24px",
        backgroundColor: "#5E70DD",
        borderRadius:"10px",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: "700",
        lineHeight: "25.6px",
        color: "#FFFFFF"
    },
    boxBtn: {
        width: "182px",
        textTransform: "initial" as "initial",
        backgroundColor: "#5E70DD" ,
        height: "56px",
        borderRadius: "12px",
        border: "2px 0px 0px 0px",
        color: "#FFFFFF",
        fontSize : "20px",
        fontWeight : 500,
        fontFamily : "Manrope",
    },
    footerText: {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25.2px",
        color: "#FFFFFF",
        marginBottom: "80px"
    },
    emailText: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "21.86px",
        color: "#FFFFFF",
        cursor: "pointer"
    },
    smallTypo: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#ECECEC"

    },
    signBtn: {
        width: "125px",
        height: "34px",
        padding: "12px 24px 12px 24px",
        backgroundColor: "#000000",
        fontFamily: "Epilogue",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#FFFFFF",
        textTransform: "initial" as "initial"
    },
    contentTitle: {
        fontFamily: "Manrope",
        fontWeight: 700,
        fontSize: "22.1px",
        lineHeight: "30.18px",
        color: "#000000"
      },
      contentTypo: {
        fontFamily: "Manrope",
        fontSize: "14.73px",
        fontWeight: 400,
        lineHeight: "20.12px",
        width: "301px"
      },
      signupBtn: {
        width: "108px",
        height: "34px",
        textTransform: "capitalize" as "capitalize",
        color:"#FFFFFF",
        backgroundColor: "#000000",
        fontFamily: "Epilogue",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px"
      },
      terms: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#FFFFFF"
      },
      gradientBox: {
        width: "100%",
        backgroundSize: "cover",
      },
      spanText: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "21.86px",
        color: "#000000"
      },
      typos: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "21.86px",
        color: "#000000"
      },
}
// Customizable Area End
