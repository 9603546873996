import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { createRef } from "react";

// Customizable Area Start
interface Service {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      description: string;
    };
  }

  interface SelectedService{
    id : string;
    checked : boolean;
  }
  

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    progress: number;
    currentTab: number;
    fieldValue: string;
     options: any[];
     radioValue: boolean
     secondRadioValue: boolean,
     teenPreferenceData: any
     radioStatus: string
     userDob:any
     parentFullname: string
     parentEmail: string
     parentCellno: string
     userFullName: string
     userTitle: string
     userWorkEmail: string
     firstReccomondName: string
     firstReccomondEmail: string
     secondReccomondName: string
     secondReccomondEmail: string
     firstName : string
     lastName : string,
     schoolOptions : any[],
     homeAddress : string,
     selectedServices : SelectedService[],
     file : File | null,
     preview : any,
     emailError : boolean,
     emailError2 : boolean,
     cellError : boolean,
     zipError : boolean,
     allServices : Service[]
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class TeenPreferenceController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    APiCallId: string = "";
    teenPreferenceCallId: string = "";
    updateprofileRelatedCallId: string = "";
    updateprofileCallId: string = "";
    fileInputRef = createRef<HTMLInputElement>();
    allServicesApiId: string = "";


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];

        this.state = {
            progress: 0,
            currentTab: 0,
            fieldValue: "",
             options: [
                { id: 1, label: 'Elgin St. Celina' },
                { id: 2, label: '6391 Elgin St. Celina, Delaware 10299' },
              ],
              radioValue: false,
              secondRadioValue: false,
              teenPreferenceData: {},
              radioStatus: "",
              userDob: null,
              parentFullname: "",
              parentEmail: "",
              parentCellno: "",
              userFullName: "",
              userTitle: "",
              userWorkEmail: "",
              firstReccomondName: "",
              firstReccomondEmail: "",
              secondReccomondName: "",
              secondReccomondEmail: "",
              firstName : "",
              lastName : "",
              schoolOptions : [
                { id: 1, label: '112 Alc Seat-Based' },
                { id: 2, label: '1702 Lake Roberts Landing Dr' },
                { id: 3, label: '1St Cerebral Palsy of Nj' },
              ],
              homeAddress : "",
              selectedServices : [],
              file  :null,
              preview : null,
              emailError : false,
              emailError2 :false,
              cellError  :false,
              zipError : false,
              allServices : []
            // Customizable Area End
        }
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiRequestCallId === this.teenPreferenceCallId) {
            this.setState({ teenPreferenceData: responseJson })
        }
        if (apiRequestCallId === this.allServicesApiId) {
            this.setState({ allServices: responseJson.data })
        }
        if(apiRequestCallId === this.updateprofileCallId){
            this.props.navigation.navigate("UserProfileBasicBlock");
        }

        // Customizable Area End
    }
    // Customizable Area Start
    emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    isValidEmail = (email:string) => this.emailRegex.test(email);
    zipRegex = /^[0-9]{5,6}$/;

    handleFile = (file: File) => {
        // Validate file type
        const validTypes = ["image/jpeg", "image/png", "application/pdf"]
        if (!validTypes.includes(file.type)) {
          alert("Please upload a JPG, PNG or PDF file")
          return
        }
    
        // Validate file size (10MB)
        if (file.size > 10 * 1024 * 1024) {
          alert("File size should not exceed 10MB")
          return
        }
    
        this.setState({file : file})
    
        // Create preview for images
        if (file.type.startsWith("image/")) {
          const reader = new FileReader()
          reader.onloadend = () => {
            this.setState({preview : reader.result as string})
          }
          reader.readAsDataURL(file)
        } else {
          // For PDFs, show an icon or placeholder
          //setPreview("/placeholder.svg?height=200&width=200")
        }
      }
    
      handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
    
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          this.handleFile(e.dataTransfer.files[0])
        }
      }
    
      handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        e.stopPropagation()
      }
    
      handleClick = () => {
        this.fileInputRef.current?.click()
      }
    
      handleClear = () => {
        this.setState({
            file : null,
            preview : ""
        })
        if (this.fileInputRef.current) {
          this.fileInputRef.current.value = ""
        }
      }
    

  handlePrevious = () => {
    this.setState({progress: this.state.progress - 11.11, currentTab: this.state.currentTab-1})
  }

  handleNext = () => {
    console.log("state", this.state.currentTab, this.state)
        if(this.state.currentTab === 9){
            this.updateprofileRelatedInfo()
            this.updateprofile()
        }else{
            this.setState({progress: this.state.progress + 11.11, currentTab: this.state.currentTab+1})
        }
  }

  toggleCheckboxSelection = (service: Service) => {
    console.log("checked",this.state.selectedServices)
    this.setState((prevState) => {
        const existingService = prevState.selectedServices.find(
            (item: SelectedService) => item.id === service.id
        );

        return {
            selectedServices: existingService
                ? prevState.selectedServices.filter((item: SelectedService) => item.id !== service.id)
                : [...prevState.selectedServices, { id: service.id, checked: true }]
        };
    });
};




  isNextDisabled = () => {
    const { currentTab, } = this.state;

    switch (currentTab) {
        case 0: return !this.state.radioValue && !this.state.secondRadioValue;
        case 1: return !this.state.firstName || !this.state.lastName;
        case 2: return !this.state.homeAddress;
        case 3: return !this.state.userDob;
        case 4: return !this.state.parentFullname || !this.state.parentEmail || !this.state.parentCellno;
        case 5: return !this.state.fieldValue;
        case 6: return !this.state.file;
        case 7: return !this.state.userFullName || !this.state.userTitle || !this.state.userWorkEmail;
        default: return false;
    }
};

  handleChange = (event: any) => {
    this.setState({fieldValue: event.target.value})
  }
  handleRadio = (event: any) => {
    let { name, id } = event.target
    if(name === "radio1"){
        this.setState({radioValue: true, secondRadioValue: false, radioStatus: id})
    }else{
        this.setState({secondRadioValue: true , radioValue: false, radioStatus: id}) 
    }
  }

    async componentDidMount() {
        this.getAllServicesApi();
    }

    getAllServicesApi = () => {
        const header = {
            "Content-Type": configJSON.contenttypeApiValidateMobileNo,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.allServicesApiId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllServices
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleChangeData = (event: any) => {
        let { name, value } = event.target
        if (name == "dob") {
            this.setState({ userDob: value })
        } else if (name == "parentName") {
            this.setState({ parentFullname: value })
        } else if (name == "parentEmail") {
            const isValid = this.isValidEmail(value); 
            this.setState({ parentEmail: value , emailError : !isValid})
        } else {
            let cleanedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
            let formattedValue = cleanedValue;

            if (cleanedValue.length > 3) {
                formattedValue = `(${cleanedValue.slice(0, 3)}) ${cleanedValue.slice(3, 6)}`;
            }
            if (cleanedValue.length > 6) {
                formattedValue += `-${cleanedValue.slice(6, 10)}`;
            }

            const isValid = cleanedValue.length === 10;
            this.setState({ parentCellno: formattedValue, cellError: !isValid });
        }
    }

    handleFullNameData = (event: any) => {
        let { name, value } = event.target
        if (name == "firstName") {
            this.setState({ firstName: value })
        } else if (name == "lastName") {
            this.setState({ lastName: value })
        }

    }

    handleZipCodeChange = (e : any) => {
        const { value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        const zipRegex = /^[0-9]{5,6}$/;
    
        this.setState({
            homeAddress: numericValue,
            zipError: !zipRegex.test(numericValue),
        });
    };
    

    handleRepresentData = (event: any) => {
        let { name, value } = event.target
        if (name == "userFullName") {
            this.setState({ userFullName: value })
        } else if (name == "userTitle") {
            this.setState({ userTitle: value })
        } else {
            const isValid = this.isValidEmail(value); 
            this.setState({ userWorkEmail: value , emailError : !isValid})
        }

    }

    handleRecommondation = (event: any) => {
        let { name, value } = event.target

        if (name == "firstSuggestName") {
            this.setState({ firstReccomondName: value })
        } else if (name == "firstSuggestEmail") {
            const isValid = this.isValidEmail(value); 
            this.setState({ firstReccomondEmail: value , emailError : !isValid})
        } else if (name == "secondSuggestName") {
            this.setState({ secondReccomondName: value })
        } else {
            const isValid = this.isValidEmail(value); 
            this.setState({ secondReccomondEmail: value , emailError2 : !isValid})
        }
    }

    updateprofileRelatedInfo = () => {
        let data = JSON.parse(String(localStorage.getItem("login")))
        const header = {
            "Content-Type": configJSON.contenttypeApiValidateMobileNo,
            token: data?.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateprofileRelatedCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.workAndPrefrenceSaveEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "data": {
                    "parent_full_name": this.state.parentFullname,
                    "parent_school_emails": this.state.parentEmail,
                    "parent_phone_number": this.state.parentCellno,
                    "coach_one_full_name": this.state.firstReccomondName,
                    "coach_one_email": this.state.firstReccomondEmail,
                    "coach_two_full_name": this.state.secondReccomondName,
                    "coach_two_email": this.state.secondReccomondEmail,
                    "representative_name": this.state.userFullName,
                    "representative_email": this.state.userWorkEmail,
                    "representative_title": this.state.userTitle,
                    "sub_categories": this.state.selectedServices
                }
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    updateprofile = () => {
        let data = JSON.parse(String(localStorage.getItem("login")))
        const header = {
            "Content-Type": configJSON.contenttypeApiValidateMobileNo,
            token: data?.token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.updateprofileCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.putAPIEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify({
                "first_name": this.state.firstName, 
                "last_name":this.state.lastName, 
                "phone_number":"",
                "school":this.state.fieldValue,
                "age":"",
                "date_of_birth":this.state.userDob,
                "address":this.state.homeAddress,
                "city":"",
                "state":"",
                "postal_code":"",
                "photo": "",
                "id_card" : this.state.preview
            })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // Customizable Area End

}

