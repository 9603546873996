import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    InputAdornment,
    TextField,
    Typography, Paper
} from "@mui/material";
import { adamImage, eyeIcon, jennyImage, logo, searchIcon, userIcon } from "./assets"
import { styled } from "@mui/material/styles";
import BookingRequest from "../../rolesandpermissions2/src/Rolesandpermissions2.web"
import Settings2 from "../../settings2/src/Settings2.web";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

import AdminDashboardController, {
    Props,
} from "./AdminDashboardController";

const StyledTextField = styled(TextField)({
    width: "335px",
    "@media (max-width: 500px)": {
        width: "294px !important",
        height: "33px !important",
        background: "#FFFFFF",
        "& .MuiInputBase-input": {
            fontSize: "10.7px"
        }
    }
});

const StyledTypo1 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "12px !important"
    }
});

const StyledTypo2 = styled(Typography)({
    "@media (max-width: 500px)": {
        fontSize: "12px !important",
        paddingRight: "15px"
    }
});

const TableComponent = styled(Box)({
    width: "100%",
    "& .tableText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        letterSpacing: "0.01em",
        color: "#000000"
    },
    "&.emptyBox": {
        maxWidth: "263px",
        width: "100%",
        minHeight: "43px",
        borderRadius: "10px",
    },
    "& .tableHeadText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "21.86px",
        letterSpacing: "0.01em",
        color: "#131313"
    },
    "& .paidBtn": {
        maxWidth: "263px",
        width: "100%",
        minHeight: "43px",
        borderRadius: "10px",
        backgroundColor: "#5E70DD!important",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px",
        color: "#FFFFFF",
        textTransform: "capitalize",
        "@media (max-width: 768px)": {
            width: "210px",
        },
        "& .rowNameText": {
            fontFamily: "Manrope",
            fontSize: "14px",
            fontWeight: 600,
            lineHeight: "100%",
            letterSpacing: "1%",
            color: "#323C47"
        }
    }

})

const StyledSaveButtonAdminProfile = styled(Button)({
    "@media (max-width: 500px)": {
        height: "28px !important",
        width: "117px !important",
        alignSelf: "flex-start",
        fontSize: "10.5px !important",
        paddingRight: "15px !important"
    }
});

const MainBox = styled(Box)({
    "@media (max-width: 500px)": {
        paddingRight: "0px !important"
    }
});

const StyledBox1 = styled(Box)({
    "@media (max-width: 1024px)": {
        flexDirection: "column",
        alignItems: "center",
    }
});

const StyledBox2 = styled(Box)({
    "@media (max-width: 800px)": {
        flexDirection: "column",
        gap: "20px !important"
    }
});

const MainContainer = styled(Box)({
    width: "100%",
    overflowX: "hidden",
    justifyContent: "center",
    "@media (max-width: 600px)": {

    },
    "& .navBoxContainer": {
        width: "100%",
        height: "74px",
        borderBottom: "1px solid #C1C7CD",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "@media (max-width: 1230px)": {
            padding: "0 20px"
        },
        "@media (max-width: 768px)": {
            padding: "0px",
            justifyContent: "space-around",
        },
    },
    "& .navBox": {
        width: "1200px",
        height: "74px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 768px)": {
            padding: "0px",
            justifyContent: "space-around",
        },
    },
    "& .nameBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "213px",
        width: "100%",
        borderLeft: "1px solid #D6DDEB",
        height: "48px",
        "@media (max-width: 768px)": {
            display: "none"
        },
    },
    "& .userIcon": {
        display: "none",
        "@media (max-width: 768px)": {
            display: "block"
        },
    },

    "& .nameText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#39393F"
    },
    "& .adminText": {
        fontFamily: "Manrope",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "28.8px",
        color: "#25324B",
        paddingTop: "65px",
        paddingBottom: "24px",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },
    },
    "& .ContentContainer": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    "& .alignContainer": {
        width: "100%",
        maxWidth: "1200px",
        flexDirection: "column",
        display: "flex",
        "@media (max-width: 1230px)": {
            padding: "0 20px"
        }
    },

    "& .tabsText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px",
        color: "#7C8493"
    },
    "& .tabsAlignment": {
        paddingLeft: "108px",
        width: "100%",
        maxWidth: "1178px",
        borderBottom: "1px solid #D6DDEB",
        height: "37px",
    },
    "& .searchBox": {
        width: "100%",
        borderRadius: "10px",
        fontFamily: "Manrope",
        fontSize: "15px",
        fontWeight: 400,
        lineHeight: "20.49px",
        color: "#8BA3CB"
    },
    "& .bookingRequest": {
        maxWidth: "625px",
        width: "100%",
        "@media (max-width: 1210px)": {
            maxWidth: "100%"
        },
    },
    "& .messageContainer": {
        maxWidth: "528px",
        width: "100%",
        "@media (max-width: 1210px)": {
            maxWidth: "100%",
            marginTop: "20px"
        },
    },
    "& .boxAlign": {
        maxWidth: "624px",
        width: "100%",
        borderRadius: "10px",
        backgroundColor: "#F5F7FA",
        "@media (max-width: 1210px)": {
            maxWidth: "100%"
        },
    },
    "& .requestText": {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 700,
        lineHeight: "21.6px",
        color: "#25324B",
        paddingBottom: "12px",
        "@media (max-width: 768px)": {
            textAlign: "center"
        },
    },
    "& .serviceText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "21.86px",
        letterSpacing: "0.01em",
        color: "#5E5C5C"
    },
    "& .customBtn": {
        maxWidth: "109px",
        width: "100%",
        height: "30px",
        borderRadius: "10px",
        border: "1px solid #5E70DD",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px"
    },
    "& .seeMore": {
        color: "#5E70DD",
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "21px"
    },
    "& .tectText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        letterSpacing: "0.01em",
        color: "#000000"
    },
    "& .tableBtn": {
        maxWidth: "203px",
        width: "100%",
        height: "43px",
        borderRadius: "10px",
        backgroundColor: "#5E70DD !important",
        color: "#FFFFFF",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "25.6px",
        textTransform: "capitalize",
        "@media (max-width: 768px)": {
            width: "50%"
        },
    },
    "& .teenText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "16.8px",
    },
    "& .secondBox": {
        maxWidth: "528px",
        width: "100%",
        borderRadius: "10px",
        backgroundColor: "#F5F7FA",
        "@media (max-width: 1210px)": {
            maxWidth: "100%",
        },
    },
    "& .adamText": {
        fontFamily: "Manrope",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24.59px",
        color: "#0D0D0D"
    },
    "& .adamContent": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        color: "#A0A0A0"
    },
    "& .tabsBox": {
        width: "100%",
        display: "flex",
        gap: "40px",
        flexWrap: "wrap",
        borderBottom: "1px solid #D6DDEB",
        "@media (max-width: 768px)": {
            flexDirection: "column",
            alignItems: "center",
            gap: "20px"
        },
    },
    "& .managePadding": {
        padding: "0 44px 64px 33px",
        "@media (max-width: 768px)": {
            padding: "0 10px 64px 10px",
        }
    },
    "& .manageMessge": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
            padding: "10px",
        }
    }
})


export default class AdminDashboard extends AdminDashboardController {
    constructor(props: Props) {
        super(props);

    }

    renderAdminProfileTab() {
        return (
            <MainBox style={{
                display: "flex", gap: "20px", marginTop: "30px",
                flexDirection: "column", paddingRight: "60px", paddingBottom: "40px"
            }}>
                {this.state.adminProfileEditBtn && (
                    <StyledTypo2 style={{
                        width: "fit-content", height: "19px", color: "#548CFF",
                        fontSize: "16px", fontWeight: 600, fontFamily: "Roboto",
                        alignSelf: "flex-end", cursor: "pointer"
                    }}
                        onClick={() => this.handleAdminProfileEditBtn()}
                        data-test-id="adminEditTestBtn"
                    > Edit
                    </StyledTypo2>
                )}
                <StyledBox1 style={{ display: "flex", gap: "50px", }}>
                    <Box style={{
                        display: "flex", alignItems: "center", justifyContent: "center",
                        height: "148px", width: "148px", cursor: "pointer",
                        border: "4px solid #DDE1E6", borderRadius: "100%",
                        background: "#5E70DD", flexDirection: "column",
                    }}
                    >
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            accept="image/*"
                        />
                        {!this.state.profileImageUrl &&
                            <>
                                <CameraAltOutlinedIcon style={{ color: "#FFFFFF" }} />
                                <Typography style={{
                                    width: "fit-content", height: "23px", textAlign: "center", color: "#FFFFFF",
                                    fontFamily: "Inter", fontWeight: 700, fontSize: "14px"
                                }}
                                > Edit picture
                                </Typography>
                            </>
                        }
                        {(this.state.profileImageUrl?.length > 0) &&
                            <img
                                src={this.state.profileImageUrl}
                                alt="profile_image"
                                style={{ height: "148px", width: "148px", borderRadius: "100%" }}
                            />
                        }
                    </Box>
                    <Box style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                        <StyledBox2 style={{ display: "flex", gap: "40px" }}>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                <StyledTypo1 style={{
                                    width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                    fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                }}
                                > First Name
                                </StyledTypo1>
                                <StyledTextField
                                    type="text"
                                    style={{
                                        height: "50px", borderRadius: "8px", textAlign: "center",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                        fontSize: "16px", justifyContent: "center",
                                    }}
                                    sx={{
                                        input: {
                                            color: '#515B6F', fontWeight: 600,
                                            fontSize: "16px", fontFamily: "Manrope"
                                        },
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#010101",
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    }}
                                    placeholder="First Name"
                                >
                                </StyledTextField>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                <StyledTypo1 style={{
                                    width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                    fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                }}
                                > Last Name
                                </StyledTypo1>
                                <StyledTextField
                                    type="text"
                                    style={{
                                        height: "50px", borderRadius: "8px", textAlign: "center",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                        fontSize: "16px", outline: "none",
                                        justifyContent: "center",
                                    }}
                                    sx={{
                                        input: {
                                            color: '#515B6F', fontWeight: 600,
                                            fontSize: "16px", fontFamily: "Manrope"
                                        },
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#010101",
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    }}
                                    placeholder="Last Name"
                                >
                                </StyledTextField>
                            </Box>
                        </StyledBox2>
                        <StyledBox2 style={{ display: "flex", gap: "40px" }}>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                <StyledTypo1 style={{
                                    width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                    fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                }}
                                > Email
                                </StyledTypo1>
                                <StyledTextField
                                    disabled
                                    type="email"
                                    style={{
                                        height: "50px", borderRadius: "8px", textAlign: "center",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                        fontSize: "16px", outline: "none",
                                        justifyContent: "center",
                                    }}
                                    sx={{
                                        input: {
                                            color: '#515B6F', fontWeight: 600,
                                            fontSize: "16px", fontFamily: "Manrope"
                                        },
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#010101",
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    }}
                                    placeholder="Email"
                                >
                                </StyledTextField>
                            </Box>
                            <Box style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                <StyledTypo1 style={{
                                    width: "fit-content", height: "26px", textAlign: "start", color: "#515B6F",
                                    fontFamily: "Manrope", fontWeight: 600, fontSize: "16px"
                                }}
                                > Cell Number
                                </StyledTypo1>
                                <StyledTextField
                                    type="text"
                                    style={{
                                        height: "50px", borderRadius: "8px", textAlign: "center",
                                        border: "1px solid #D6DDEB", fontFamily: "Manrope",
                                        fontSize: "16px", outline: "none",
                                        justifyContent: "center",
                                    }}
                                    sx={{
                                        input: {
                                            color: '#515B6F', fontWeight: 600,
                                            fontSize: "16px", fontFamily: "Manrope"
                                        },
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "#010101",
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                    }}
                                    placeholder="Cell Number"
                                >
                                </StyledTextField>
                            </Box>
                        </StyledBox2>
                    </Box>
                </StyledBox1>
                {this.state.adminProfileSaveBtn && (
                    <StyledSaveButtonAdminProfile
                        style={{
                            background: "#5E70DD", width: "205px", height: "43px",
                            borderRadius: "10px", padding: "12px 24px", color: "#FFFFFF",
                            fontFamily: "Roboto", fontWeight: 700, fontSize: "16px",
                            textTransform: "none", alignSelf: "flex-end", margin: "40px 0px"
                        }}
                        onClick={() => this.handleAdminProfileSaveBtn()}
                        data-test-id="adminProfileSaveTestBtn"
                    >
                        Save
                    </StyledSaveButtonAdminProfile>
                )}
            </MainBox>
        )
    }

    renderFindUserTab() {
        return (
            <MainBox style={{
                display: "flex",
                paddingBottom: "40px",
                marginTop: "30px",
                gap: "20px",
                paddingRight: "60px",
                flexDirection: "column",
            }}>
                <Box style={{
                    alignSelf: "flex-start",
                    color: "black",
                    width: "fit-content",
                    height: "30px",
                }}>
                    <Box style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "40px"
                    }
                    }>
                        <Typography
                            data-test-id="handlecolor"
                            className="teenText"
                            style={
                                {
                                    color:
                                        `${this.state.selectedTab === 'seniors' ?
                                            '#25324B' : '#6C7483'}`,
                                            fontFamily: "Manrope",
                                            fontSize: "16px",
                                            fontWeight: 600,
                                            lineHeight: "160%",
                                }
                            }
                            onClick={() =>
                                this.handleSelectedTab('seniors')
                            }
                        >
                            Seniors
                        </Typography>
                        <Typography
                            data-test-id="handlecolors"
                            onClick={() => this.handleSelectedTab('students')}
                            className="teenText"
                            style={{ color: `${this.state.selectedTab === 'students' ? '#25324B' : '#6C7483'}` 
                            ,fontFamily: "Manrope",
                            fontSize: "16px",
                            lineHeight: "160%",
                            fontWeight: 600,
                            }}>
                            Student
                        </Typography>
                    </Box>
                </Box>
                {this.state.selectedTab === 'students' ? (
                    <>
                        <StyledTypo2 style={{
                            width: "fit-content",
                             height: "19px", color: "black",
                            fontSize: "24px", fontWeight: 700,
                             fontFamily: "Manrope",alignSelf: "flex-start"
                        }}
                            data-test-id="adminEditTestBtn"
                        > 
                        Search Result
                        </StyledTypo2>
                        <Box 
                        style={
                            { padding: "28px 0px"
                                 }
                                 }
                                 >
                            <TextField
                                type="text"
                                className="searchBox"
                                placeholder="Search for Students"
                                sx={{
                                    height: "50px",
                                    border: "none",
                                    backgroundColor: "#F5F7FA",
                                    '& .MuiOutlinedInput-root': {
                                        height: "50px",
                                        '& fieldset': {
                                            border: "none",
                                        }
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={searchIcon.default} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <TableComponent>
                            <TableContainer style={{ boxShadow: "none" }} component={Paper}>
                                <Table sx={{ width: "100%" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" className="tableHeadText" style={{ width: "235px !important", }} >User Name</TableCell>
                                            <TableCell align="left" className="tableHeadText" >Location</TableCell>
                                            <TableCell align="left" className="tableHeadText" >Service</TableCell>
                                            <TableCell align="left" className="tableHeadText" >Availability</TableCell>
                                            <TableCell align="left" className="tableHeadText" ></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            key={"row.name"}
                                            sx={{'&:last-child td, &:last-child th': { border: 0 },'& td, & th': { border: 0 }}}
                                        >
                                            <TableCell 
                                            component="th" scope="row" >
                                                <Box
                                                 style={{ display: "flex", gap: "12px", height: "43px", alignItems: "center" }}>
                                                    <img src={jennyImage.default} 
                                                    />
                                                    <Typography 
                                                    className="rowNameText">Jenny Wilson</Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell 
                                            align="left" className="tableText">New York, New</TableCell>
                                            <TableCell align="left" className="tableText">Tech Help, Indoor Help, Organizing</TableCell>
                                            <TableCell align="left" className="tableText">Mon, Tue, Thu</TableCell>
                                            <TableCell align="left">
                                                <Button 
                                                className="emptyBox"
                                                ></Button> 
                                                </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableComponent>
                    </>
                ) : this.state.selectedTab === 'seniors' ? (
                    <>

                        <StyledTypo2 style={{
                            width: "fit-content", height: "19px", color: "black",
                            fontSize: "24px", fontWeight: 700, fontFamily: "Manrope",
                            alignSelf: "flex-start"
                        }}
                            data-test-id="adminEditTestBtn"
                        > Search Result
                        </StyledTypo2>
                        <Box style={{ padding: "28px 0px" }}>
                            <TextField
                                className="searchBox"
                                placeholder="Search for Seniors"
                                type="text"
                                sx={
                                    {
                                        border: "none",
                                        height: "50px",
                                        backgroundColor: "#F5F7FA",
                                        '& .MuiOutlinedInput-root': {
                                            height: "50px",
                                            '& fieldset': {
                                                border: "none"
                                            }
                                        }
                                    }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                        >
                                            <img
                                                src={searchIcon.default}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Box>
                        <TableComponent>
                            <TableContainer style={{ boxShadow: "none" }} component={Paper}>
                                <Table sx={{ width: "100%" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                align="left"
                                                className="tableHeadText"
                                                style={{ width: "235px !important", }} >User Name</TableCell>
                                            <TableCell
                                                className="tableHeadText"
                                                align="left"
                                            >Phone number</TableCell>
                                            <TableCell align="left" className="tableHeadText" >Email</TableCell>
                                            <TableCell align="left" className="tableHeadText" >Location</TableCell>
                                            <TableCell align="left" className="tableHeadText" >
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            sx={{
                                                '& td, & th': { border: 0 },
                                                '&:last-child td, &:last-child th': { border: 0 },
                                            }}
                                            key={"row.name"}
                                        >
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <Box
                                                    style={
                                                        {
                                                            alignItems: "center",
                                                            height: "43px",
                                                            gap: "12px",
                                                            display: "flex",
                                                        }
                                                    }
                                                >
                                                    <img src={jennyImage.default}
                                                    />
                                                    <Typography
                                                        className="rowNameText"
                                                    >
                                                        Jenny Wilson
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell
                                                align="left"
                                                className="tableText">(201) 555-1111</TableCell>
                                            <TableCell
                                                align="left" className="tableText">your.name@email.com</TableCell>
                                            <TableCell align="left"
                                                className="tableText">New York, New</TableCell>
                                            <TableCell align="left">
                                                <Button className="paidBtn"
                                                >Create new booking request</Button>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableComponent>
                    </>
                ) : null}
            </MainBox>
        )
    }

    renderSettingPage = (currentTab: number) => {
        return <>
            {(currentTab == 5) && <Settings2 navigation={this.props.navigation} id={"1"} />}</>
    }

    render() {
        let { tabs } = this.state

        let tableData = this.state.bookingRequestData?.requests || []
        let tableData2 = [
            { "name": "Jenny Wilson", "tech": "Tech Help", "btnName": "Search with Student", "content": "Remember that concert last y..." },
            { "name": "Jenny Wilson", "tech": "Tech Help", "btnName": "Search with Student", "content": "Remember that concert last y..." }
        ]

        return (
            <MainContainer>
                <Box className="navBoxContainer">
                    <Box className="navBox">
                        <img src={logo.default} />
                        <img src={userIcon.default} className="userIcon" />
                        <Box className="nameBox">
                            <Typography className="nameText">{this.state.userName?.fullName}</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box className="ContentContainer">
                    <Box className="alignContainer">
                        <Typography className="adminText">Admin Role</Typography>
                        <Box className="tabsBox">
                            {tabs.map((item, index) =>
                                <Box>
                                    <Typography className="tabsText" style={{ color: `${(index == this.state.currentTab) ? "#25324B" : "#7C8493"}` }}
                                        data-test-id="currentTabId"
                                        onClick={() => this.setState({ currentTab: index })}
                                    >{item.label}</Typography>
                                    {(index == this.state.currentTab) ? <Box style={{ backgroundColor: "#4640DE", height: "4px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px", marginTop: "8px" }}>
                                    </Box> : null}
                                </Box>
                            )}
                        </Box>
                        {(this.state.currentTab == 0) && <Box>

                            <Box style={{ padding: "28px 0px" }}>
                                <TextField
                                    type="text"
                                    placeholder="Search for Students/Seniors"
                                    className="searchBox"
                                    sx={{
                                        height: "50px",
                                        backgroundColor: "#F5F7FA",
                                        border: "none",
                                        '& .MuiOutlinedInput-root': {
                                            height: "50px",
                                            '& fieldset': {
                                                border: "none",
                                            }
                                        }
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={searchIcon.default} />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Box>

                            <Box style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", width: "100%" }}>
                                <Box className="bookingRequest"> 
                                    <Typography className="requestText">Booking Requests</Typography>
                                    <Box className="boxAlign"> 
                                        <Box className="managePadding">
                                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "76px" }}>
                                                <Typography className="serviceText">Senior</Typography>
                                                <Typography className="serviceText">Service</Typography>
                                                <Box className="customBtn">
                                                    <img src={eyeIcon.default} />
                                                    <Typography className="seeMore">See More</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={{ width: "100%", border: "1px solid #DADADA" }}></Box>

                                            <Box style={{ display: "flex", flexDirection: "column", }}>
                                                {
                                                    tableData.map((row: any) =>
                                                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "16px" }}>
                                                            <Box style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                                                                <img src={jennyImage.default} />
                                                                <Typography className="tectText" style={{ fontWeight: 600 }}>{row.recipient_first_name} {row.recipient_last_name}</Typography>
                                                            </Box>
                                                            <Typography className="tectText">{row.service_name}</Typography>
                                                            <Button className="tableBtn">Search with Student</Button>
                                                        </Box>
                                                    )

                                                }
                                            </Box>
                                        </Box>

                                    </Box>
                                </Box>

                                <Box className="messageContainer">
                                    <Box className="manageMessge">
                                        <Typography className="requestText">Messages</Typography>
                                        <Box style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                                            <Typography className="teenText" style={{ color: `${!this.state.selected ? "#25324B" : "#7C8493"}` }}
                                                onClick={this.handleSelected}
                                            >Teens</Typography>
                                            <Typography className="teenText" style={{ color: `${this.state.selected ? "#25324B" : "#7C8493"}` }}
                                                onClick={this.handleSelected}
                                            >Seniors</Typography>
                                        </Box>
                                    </Box>
                                    <Box className="secondBox">
                                        <Box style={{ padding: "47.03px 22.97px 30px" }}>
                                            <Box style={{ display: "flex", flexDirection: "column", gap: "16px" }}>{
                                                tableData2.map((item) =>
                                                    <Box style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                        <img src={adamImage.default} />
                                                        <Box>
                                                            <Typography className="adamText">{item.name}</Typography>
                                                            <Typography className="adamContent">{item.content}</Typography>
                                                        </Box>
                                                    </Box>
                                                )}
                                            </Box>
                                        </Box>

                                    </Box>

                                </Box>

                            </Box>
                        </Box>}

                        {(this.state.currentTab == 2) && <BookingRequest navigation={this.props.navigation} id={"1"} />}

                        {this.renderSettingPage(this.state.currentTab)}

                        {
                            (this.state.currentTab == 1) && this.renderAdminProfileTab()
                        }
                        {
                            (this.state.currentTab == 4) && this.renderFindUserTab()
                        }
                    </Box>
                </Box>

            </MainContainer>
        )
    }
}
// Customizable Area End