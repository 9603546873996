import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Checkbox,
    IconButton,
    Input,
    Paper,
    Radio,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { logo, prevIcon, nextIcon, bottomImage, userIcon, upload } from "./assets"
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from "@mui/icons-material/Close"

import TeenPreferenceController, {
    Props,
} from "./TeenPreferenceController";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";


const MainContainer = styled(Box)({
    width:"100%",
    overflowX:"hidden",
    "@media (max-width: 600px)": {
    },
    "& .navBox": {
        height: "74px",
        padding: "0px 124px 0px 84px",
        border: "1px solid #C1C7CD",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        "@media (max-width: 768px)": {
            padding: "0px",
            justifyContent:"space-around",
        },
    },
    "& .nameBox": {
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        maxWidth:"213px",
        width:"100%",
        borderLeft:"1px solid #D6DDEB",
        height:"48px",
        "@media (max-width: 768px)": {
            display: "none"
        },
    },
    "& .userIcon": {
        display:"none",
        "@media (max-width: 768px)": {
            display: "block"
        },
    },

    "& .nameText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "25.6px",
        color: "#39393F"
    },
    "& .commonTypo": {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
        color:"#25324B"
    },
    "& .optionText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "21.86px",
        color: "#252729",
        width:"701px"
    },
    "& .radioTextAlign": {
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
        maxWidth:"701px",
        "@media (max-width: 768px)": {
            width: "58%",
        },
    },
    "& .prevBtn": {
        maxWidth: "205px",
        width:"100%",
        height: "43px",
        borderRadius: "10px",
        border: "1px solid #5E70DD" 
    },
    "& .nextBtn": {
        maxWidth: "205px",
        width:"100%",
        height: "43px",
        borderRadius: "10px",
        backgroundColor: "#5E70DD !important"
    },
});

const BirthContainer = styled(Box)({
        display:"flex",
        alignItems:"center",
        flexDirection:"column",
        gap:"32px",

    "& .inputBox": {
        maxWidth: "360px",
        height: "56px",
        width:"100%",
        borderRadius: "10px",
        border: "1px solid #CBD5E1",
        paddingLeft:"26px",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color:"#0F172A"
},
    "& .selectBox": {
        maxWidth: "360px",
        width:"100%",
        height: "56px",
        borderRadius: "10px",
        border: "1px solid #CBD5E1",
        fontFamily: "Manrope",
        paddingLeft: "26px",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#000000"
    },
    "& .menuText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "21.86px",
        color: "#252729",
        border: "1px solid #F4F4F4"
    }
})

const CommunityWrap = styled(Box)({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "24px",
        maxWidth: "773px",
        width: "100%",
    "& .titleText": {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "21.86px",
        color:"#25324B"
    },
    "& .contentText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#818486"
    }
})

const BoxWrap = styled(Box)({
        maxWidth:"507px",
        width:"100%",
        display: "flex",
        flexDirection: "column",
        gap: "9px",
        alignItems: "center",
    "& .infoText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        color: "#818486",
        textAlign: "center",
    },
    "& .labelText": {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
        color: "#25324B",
        paddingBottom: "10px"
    },
    "& .commonInp": {
        maxWidth: "360px",
        width:"100%",
        height: "56px",
        border: "1px solid #CBD5E1",
        borderRadius: "10px",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A",
        paddingLeft: "26px"
    }

})

const RepresentWrap = styled(Box)({
        maxWidth: "770px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
    "& .inputDiv": {
        maxWidth: "360px",
        width:"100%",
        height: "56px",
        borderRadius: "10px",
        border: "1px solid #CBD5E1",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A",
        paddingLeft: "26px"
    },
    "& .labelText": {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
        color: "#25324B",
        paddingBottom: "16px"
    },
    "& .infoText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        color: "#818486",
        textAlign: "center",
        paddingBottom: "40px"
    },
    "& .manageAlign": {
        display: "flex",
        gap: "48px",
        width: "100%",
        alignItems: "center",
        flexWrap: "wrap",
        "@media (max-width: 768px)": {
           flexDirection:"column"
        }
    }
})

const RecomendWrap = styled(Box)({
       maxWidth:"894px",
        width:"100%",
    "& .recmTetx": {
        fontFamily: "Manrope",
        fontSize: "28px",
        fontWeight: 400,
        lineHeight: "33.6px",
        color: "#25324B",
        paddingBottom: "24px",
        paddingTop:"30px"
    },
    "& .abtRecm": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "19.12px",
        color: "#000000"
    },
    "& .spanText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 500,
        lineHeight: "16.39px",
        color: "#818486"
    },
    "& .fullText": {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "19.12px",
        color: "#25324B"
    },
    "& .comInput": {
        width: "360px",
        height: "56px",
        paddingLeft: "16px",
        borderRadius: "10px",
        border: "1px solid #CBD5E1",
        fontFamily: "Manrope",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        color: "#0F172A"
    },
    "& .alignBoxinput": {
        display: "flex",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
            display: "flex",
            justifyContent: "center",
            alignItems:"center",
            gap:"20px",
            flexDirection:"column"
         }
    },
    "& .contALign": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        "@media (max-width: 768px)": {
        textAlign: "center",
        justifyContent: "center"
        }
    },
    "& .littleText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 700,
        lineHeight: "16.39px",
        color: "#818486"
    },
    "& .bottomText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16.39px",
        color: "#818486",
        textAlign: "center",
        paddingTop:"37px"
    },
    "& .skipText": {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontWeight: 800,
        lineHeight: "16.39px",
        color:"#0085FF"
    }

})

const StyledTextField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        borderRadius: "10px",
        width:"360px",
        height : "56px",
        paddingLeft: "26px",
    },
});


const UploadBox = styled(Paper)(({ theme }) => ({
    border: `1px dashed #00000040`,
    borderRadius: "10px",
    padding: theme.spacing(4),
    textAlign: "center",
    cursor: "pointer",
    transition: "border-color 0.2s ease-in-out",
    backgroundColor: "transparent",
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  }))
  
  const PreviewBox = styled(Box)({
    position: "relative",
    width: "100%",
    height: "100%",
    minHeight: 200,
  })
  
  const CloseButton = styled(IconButton)({
    position: "absolute",
    top: 8,
    right: 8,
    zIndex: 1,
  })


export default class TeenPreference extends TeenPreferenceController {
    constructor(props: Props) {
        super(props);

    }

    renderDescribeScreen = () => {
        return <Box style={{display:"flex", alignItems:"center", flexDirection: "column", gap:"24px", width:"100%"}}>
            <Typography className="commonTypo">What best describes you?</Typography>

            <Box className="radioTextAlign">
                <Box style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <Radio name="radio1" 
                     checked={this.state.radioValue}
                     id="job"
                     onClick={this.handleRadio}
                     sx={{'&.Mui-checked': {
                        color: '#5E70DD',
                      },}}
                     />
                    <Typography className="optionText">
                    I am a high school or college student, who wants a job assisting local seniors in my community.
                    </Typography>
                 </Box>
                 <Box style={{display: "flex", justifyContent: "space-around", alignItems: "center"}}>
                    <Radio 
                     id="hire"
                    checked={this.state.secondRadioValue}
                     onClick={this.handleRadio}
                     sx={{
                        '&.Mui-checked': {
                        color: '#5E70DD',
                      }
                    }}
                      />
                    <Typography className="optionText">
                    I want to hire a student assistant for myself, my family member, my client, or my business.
                    </Typography>
                 </Box>
            </Box>

        </Box>
    }

    renderBirthDateScreen = () => {
        return <BirthContainer>
            <Typography className="commonTypo">What’s your birth date?</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        data-test-id="prefered_start_date"
                        value={this.state.userDob}
                        onChange={(newValue) => this.setState({ userDob: newValue })}
                        maxDate={dayjs()}
                        slotProps={{
                          textField: {
                            sx:{
                        //      ...webStyle.selectBox,
                              borderRadius: "10px",
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "8px",
                                height: "56px",
                                width : "360px",
                                fontSize : "16px"
                              },
                              "& .MuiInputBase-input::placeholder": {
                                color: "#0F172A",
                                opacity: 1,
                                fontFamily : "Inter"
                              },
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
        </BirthContainer>
    }

    renderAddressScreen = () => {
        return <BirthContainer>
            <Typography className="commonTypo">What’s your home address? (zip code)</Typography>
            <Box style={{ maxWidth: "360px", width: "100%" }}>
                 <TextField
                            style={{width : "360px", height  :"56px", borderRadius : "10px"}}
                            name="homeAddress"
                            variant="outlined"
                            placeholder="Enter Zip code"
                            value={this.state.homeAddress}
                            onChange={this.handleZipCodeChange}
                            error={this.state.zipError}
                            helperText={this.state.zipError ? "Invalid Zip Code (Must be 5-6 digits)" : ""}
                        />
            </Box>
        </BirthContainer>
    }

    renderSchoolScreen = () => {
        return <BirthContainer>
            <Typography className="commonTypo">What school do you go to?</Typography>
            <Box style={{ maxWidth: "360px", width: "100%" }}>
            <StyledTextField
                data-test-id="schoolAddress"
                style={{width : "360px", borderRadius : "10px"}}
                variant="outlined"
                placeholder="Enter school address"
                value={this.state.fieldValue}
                onChange={(e) => this.setState({ fieldValue: e.target.value })}
            />
            </Box>
        </BirthContainer>
    }

    renderSchoolIdScreen = () => {
        return <BirthContainer>
            <Typography className="commonTypo">School ID Check</Typography>
            <Typography className="infoText">Please upload photo of your school ID. This will give us a better way to verify your school enrolment.</Typography>
            <Box sx={{ width: "100%", maxWidth: 530, mx: "auto", p: 2}}>
      <input
        type="file"
        data-test-id="schholIdScreen"
        ref={this.fileInputRef}
        onChange={(e) => {
          if (e.target.files && e.target.files[0]) {
           this.handleFile(e.target.files[0])
          }
        }}
        accept=".jpg,.jpeg,.png,.pdf"
        style={{ display: "none" }}
      />

      {!this.state.file ? (
        <UploadBox onDrop={this.handleDrop} onDragOver={this.handleDragOver} onClick={this.handleClick}>
          <img src={upload.default} style={{height : "36px", width  :"44px", marginBottom : "30px"}} />
          <Typography  style={{fontFamily : "Helvetica", fontWeight : 400, fontSize : "13px", lineHeight : "14.95px"}}>
            Select a file or drag and drop here
          </Typography>
          <Typography style={{fontFamily : "Helvetica", fontWeight : 400, fontSize : "12px", lineHeight : "14.95px", color : "#00000066", marginTop  :"12px"}}>
            JPG, PNG or PDF, file size no more than 10MB
          </Typography>
          <Button variant="outlined" style={{color : "#0F91D2B2"}} sx={{ mt: 2 }}>
            SELECT FILE
          </Button>
        </UploadBox>
      ) : (
        <PreviewBox>
          <CloseButton onClick={this.handleClear} size="small">
            <CloseIcon />
          </CloseButton>
          {this.state.preview && (
            <Box
              component="img"
              src={this.state.preview}
              alt="File preview"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                borderRadius: 1,
              }}
            />
          )}
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {this.state.file.name}
          </Typography>
        </PreviewBox>
      )}
    </Box>
        </BirthContainer>
    }

    renderCommunityScreen = () => {
        return <CommunityWrap>
        <Typography className="commonTypo">How can you assist seniors in your local community?</Typography>
            {
                this.state.allServices?.map((item) => <Box key={item.id} style={{display: "flex", alignItems: "center", gap:"10px"}}>
                    <Checkbox 
                    onClick={()=>{this.toggleCheckboxSelection(item)}}
                    sx={{
                        '&.Mui-checked': {
                            color: '#5E70DD',
                        },
                    }} />
                <Box>
                <Typography className="titleText">{item.attributes.name}</Typography>
                <Typography className="contentText">{item.attributes.description}</Typography>
                </Box>
                </Box>
                )
            }
        
        </CommunityWrap>
    }
    

    renderParentInfoScreen = () => {
        return <BoxWrap>
            <Typography className="infoText">Please provide your parent or legal guardian's information as a safety contact. If you are under 18, we will also contact them to sign your work authorization and background check permission forms.</Typography>
                <Box style={{width:"100%", maxWidth:"400px", display:"flex", flexDirection: "column", gap: "16px"}}>
                    <Box>
                        <Typography className="labelText">Parent or Guardian’s Full Name</Typography>
                        <Input
                        type="text"
                        placeholder="Anna Hirot"
                        className="commonInp"
                        disableUnderline
                        value={this.state.parentFullname}
                        onChange={this.handleChangeData}
                        name="parentName"
                        />
                    </Box>
                    <Box>
                        <Typography className="labelText">Parent or Guardian’s Email</Typography>
                        <StyledTextField
                        type="text"
                        placeholder="email@email.com"
                        value={this.state.parentEmail}
                        onChange={this.handleChangeData}
                        name="parentEmail"
                        error={this.state.emailError}
                        helperText={this.state.emailError ? "Invalid email" : ""}
                        />
                    </Box>
                    <Box>
                        <Typography className="labelText">Parent or Guardian’s Cell</Typography>
                        <StyledTextField
                        type="text"
                        placeholder="(201) 555-5555"
                        value={this.state.parentCellno}
                        onChange={this.handleChangeData}
                        error={this.state.cellError}
                        helperText={this.state.cellError ? "Invalid Cell Number" : ""}
                        />
                    </Box>

                </Box>
        </BoxWrap>
    }

    renderRepresentativeScreen = () => {
        return <RepresentWrap>
            <Box style={{textAlign: "center"}}>
            <Typography className="commonTypo" style={{paddingBottom: "16px"}}>School Academic Advisor</Typography>
            <Typography className="infoText">Please provide contact information for your academic counselor to verify your school enrollment. If you are under 18, we will also contact them to sign your work authorization form.</Typography>
            </Box>
            <Box>
                <Box className="manageAlign">
                <Box style={{maxWidth: "360px",  width:"100%"}}>
                    <Typography className="labelText">Full Name</Typography>
                    <Input
                    type="text"
                    className="inputDiv"
                    placeholder="Ben Ugio"
                    disableUnderline
                    name="userFullName"
                    onChange={this.handleRepresentData}
                    value={this.state.userFullName}
                    />
                </Box>
                <Box style={{maxWidth: "360px",  width:"100%"}}>
                    <Typography className="labelText">Title</Typography>
                    <Input
                    type="text"
                    className="inputDiv"
                    placeholder="1702 Lake Roberts Landing Dr"
                    disableUnderline
                    name="userTitle"
                    onChange={this.handleRepresentData}
                    value={this.state.userTitle}
                    />
                </Box>
                 <Box style={{maxWidth: "360px",  width:"100%"}}>
                    <Typography className="labelText" >Work Email</Typography>
                    <StyledTextField
                    type="text"
                    placeholder="email@email.com"
                    onChange={this.handleRepresentData}
                    value={this.state.userWorkEmail}
                    error={this.state.emailError}
                    helperText={this.state.emailError ? "Invalid email" : ""}
                    />
                </Box>
                </Box>
            </Box>
        </RepresentWrap>
    }

    renderFullNameScreen = () => {
        return <RepresentWrap>
            <Box style={{textAlign: "center"}}>
            <Typography className="commonTypo" style={{paddingBottom: "16px"}}>What’s your name? </Typography>
            </Box>
            <Box>
                <Box className="manageAlign">
                <Box style={{maxWidth: "360px"}}>
                    <Typography className="labelText">First Name</Typography>
                    <Input
                    type="text"
                    className="inputDiv"
                    placeholder="John"
                    disableUnderline
                    name="firstName"
                    onChange={this.handleFullNameData}
                    value={this.state.firstName}
                    />
                </Box>
                <Box style={{maxWidth: "360px"}}>
                    <Typography className="labelText">Last Name</Typography>
                    <Input
                    type="text"
                    className="inputDiv"
                    placeholder="Doe"
                    disableUnderline
                    name="lastName"
                    onChange={this.handleFullNameData}
                    value={this.state.lastName}
                    />
                </Box>
                </Box>
            </Box>
        </RepresentWrap>
    }

    renderRecommendScreen = () => {
        return <RecomendWrap style={{width:"100%", maxWidth:"894px"}}>
            <Typography className="recmTetx" style={{textAlign: "center"}}>Recommendations</Typography>
            <Box>
                <Box className="contALign">
                <Box style={{maxWidth:"452px", width:"100%"}}>
                    <Typography className="abtRecm">About Recommendations:</Typography>
                    <Typography className="spanText">Seniors like seeing you're trusted by local adults who aren't family members. Recommenders should be adults who know you well but aren't related to you, like your high school counselor, teacher, principal, coach, religious community leader, or manager at another job.</Typography>
                </Box>
                <Box style={{maxWidth:"360px", width:"100%"}}>
                    <Typography className="abtRecm">It's a Quick Online Form - But Ask First, It's Polite!</Typography>
                    <Typography className="spanText">Ask your Recommenders for permission first, it's the polite thing to do! Let them know it's an online multiple choice form that only takes a  minute to fill out. They'll get an email from <span className="littleText">recommend@senioryear.co</span> inviting them to fill out the form.</Typography>
                </Box>
                </Box>
                <Box className="alignBoxinput">
                <Box>
                <Typography className="recmTetx" >Recommender #1</Typography>
                <Box style={{paddingBottom:"16px"}}>
                    <Typography className="fullText">Full Name</Typography>
                    <Input 
                    type="text"
                    className="comInput"
                    disableUnderline
                    placeholder="Anna Hirot"
                    value={this.state.firstReccomondName}
                    onChange={this.handleRecommondation}
                    name="firstSuggestName"
                    />
                </Box>
                <Box>
                    <Typography className="fullText">email</Typography>
                    <StyledTextField 
                    type="text"
                    placeholder="email@email.com"
                    value={this.state.firstReccomondEmail}
                    onChange={this.handleRecommondation}
                    name="firstSuggestEmail"
                    error={this.state.emailError}
                    helperText={this.state.emailError ? "Invalid email" : ""}
                    />
                </Box>
                </Box>
                <Box>
                <Typography className="recmTetx" >Recommender #2</Typography>
                <Box style={{paddingBottom:"16px"}}>
                    <Typography className="fullText">Full Name</Typography>
                    <Input 
                    type="text"
                    className="comInput"
                    disableUnderline
                    placeholder="Anna Hirot"
                    value={this.state.secondReccomondName}
                    onChange={this.handleRecommondation}
                    name="secondSuggestName"
                    />
                </Box>
                <Box>
                    <Typography className="fullText">email</Typography>
                    <StyledTextField 
                    type="text"
                    placeholder="email@email.com"
                    value={this.state.secondReccomondEmail}
                    onChange={this.handleRecommondation}
                    error={this.state.emailError2}
                    helperText={this.state.emailError2 ? "Invalid email" : ""}
                    />
                </Box>
                </Box>
                </Box>
                <Typography className="bottomText">If you <span className="littleText">don't have their permission yet</span>, you can <span className="skipText">skip</span> this section for now.</Typography>
            </Box>

        </RecomendWrap>
    }

    handlescreens = () => {
        switch(this.state.currentTab){
            case 0:
                return this.renderDescribeScreen()
            case 1:
                return this.renderFullNameScreen()
            case 2:
                return this.renderAddressScreen()
            case 3:
                return this.renderBirthDateScreen()
            case 4:
                return this.renderParentInfoScreen()
            case 5:
                return this.renderSchoolScreen()
            case 6:
                return this.renderSchoolIdScreen()
            case 7:
                return this.renderRepresentativeScreen()
            case 8:
                return this.renderRecommendScreen()
            case 9:
                return this.renderCommunityScreen()
        }
    }
    render() {
        const positionValue = (this.state.currentTab < 4) ? "fixed" : "unset"
        return (
            <MainContainer>
                <Box className="navBox">
                    <img src={logo.default} />
                    <img src={userIcon.default} className="userIcon"/>
                    <Box className="nameBox">
                        <Typography className="nameText">David Jones</Typography>
                    </Box>
                </Box>

                <Box style={{ display:"flex", flexDirection: "column", alignItems:"center", justifyContent: "center", width:"100%", gap:"18px", paddingTop:"10px", height: `${(this.state.currentTab < 4) ? "55vh" : "100vh"}` }}>

                    <Box style={{width:"100%", maxWidth: "388px", display:"flex", alignItems:"center", justifyContent: "space-around"}}>
                        <Typography style={{fontFamily: "Manrope", fontSize: "18px",
                         fontWeight: 700, lineHeight: "24.59px", color:"#3174A5"}}>{Math.ceil(this.state.progress)}%</Typography>
                        <Typography style={{fontFamily: "Manrope", fontSize: "18px", fontWeight: 700,
                        lineHeight: "24.59px", color: "#1F2224"}}>Update in progress..</Typography>
                    </Box>

                <Box style={{ width:"100%", maxWidth: "388px" , height: "5px", backgroundColor:"#C7D3EB"}}>
                <LinearProgress variant="determinate" value={this.state.progress}
                 sx={{
                        '& .MuiLinearProgress-bar1Determinate': {
                        backgroundColor: "#5E70DD",
                        },
                    }}/>
                </Box>
                 
                 <Box style={{width:"100%",display: "flex", justifyContent: "center" }}>
                   <Box> {this.handlescreens()}</Box>
                 </Box>

                </Box>
                <Box style={{width:"100%", height: "156px", marginTop:"55px", position: positionValue, bottom:"0px", backgroundSize:"cover", backgroundImage: `url(${bottomImage.default})`,
                display: "flex", justifyContent: "center",  alignItems: "center", gap: "16px" }}>
                <Button className="prevBtn" onClick={this.handlePrevious} disabled={(this.state.currentTab === 0)}><img src={prevIcon.default} /></Button>
                <Button className="nextBtn" onClick={this.handleNext} disabled={this.isNextDisabled()} ><img src={nextIcon.default} /></Button>
                </Box>

            </MainContainer>
        )
    }
}
// Customizable Area End